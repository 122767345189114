import { Component, OnInit } from '@angular/core';
import { MENUITEMLIST1 } from '../../constants/labels';

@Component({
  selector: 'app-side-menu-barqa',
  templateUrl: './side-menu-barqa.component.html',
  styleUrls: ['./side-menu-barqa.component.scss']
})

// Component displays side navigation menu across the application
export class SideMenuBarQAComponent implements OnInit {
  menuItemlist = MENUITEMLIST1;
  constructor() {
  }

  ngOnInit() {
  }

}
