<span class="loading" *ngIf="loading">
  <em class="fa fa-spinner fa-spin" style="font-size:40px;color:white"></em>
</span>
<app-header></app-header>
<app-side-menu-bar></app-side-menu-bar>

<div class="main-content">
  <div class="heading">
    <label class="heading-text">{{LossTreeHeader}}</label>
    <span class="addBtn" *ngIf="isAuthorized">
       <button class="btn btn-primary  save-btn" [ngClass]="enableSaveBtn ? 'enable': 'disable'" (click)="saveLossTree()">Save</button>
      <span title="Level0 should be selected to add a new row">
        <button class="btn btn-primary" [ngClass]="clickedLevel1 ? 'enable': 'disable'" (click)="addChild()">
          Add New
        </button></span>
    </span>
  </div>
  <div class="example-container mat-elevation-z8">
        <app-table-row [dataSource]="users"
        [displayedColumns]="displayedColumns"
        [referenceId]="'level0'"
        [iconKeyReference]="'lossTreeParent'"
        [renderTemplate]="'relative'" (addBtnEnabled)="addRow($event)" 
        (emitBtnEnable)="enableSaveBtnFn($event)"
        (emitLossTreeData)="getSaveData($event)">
      </app-table-row>

  </div>
</div>