<span class="loading" *ngIf="loading">
  <i class="fa fa-spinner fa-spin" style="font-size:40px;color:white"></i>
</span>
<app-header></app-header>
<app-side-menu-bar></app-side-menu-bar>
<div class="main-content">
  <div class="heading">
    <label class="heading-text">{{incidentDataUpload}}</label>
  </div>
<!-- [ngClass]="isAuthorized? '': 'readOnly'" -->
  <div class="dropdown" *ngIf="isAuthorized">
    <button type="button" class="btn btn-primary dropdown-toggle" (focusout)="focusOutIncidentType()"
      data-toggle="dropdown" (click)="incidentTypeDefaultValue()">
      {{defaultIncidentType}}
      <span [ngClass]="showIncidentType? 'fa fa-angle-down arrow-position' : 'fa fa-angle-up arrow-position'"></span>
    </button>
    <ul class="dropdown-menu">
      <li>
        <p class="dropdown-item" title="{{item.name}}" href="#" *ngFor="let item of incidentType" (click)="selectedIncidentType(item)">
          {{item.name}}</p></li>
    </ul>


    <button type="button" class="btn btn-primary excel-upload">
      <label for="files" style="cursor:pointer;font-weight:400;">{{incidentUpload}} <i class="upload-icon"></i></label>
      <input id="files" #files style="visibility:hidden;" (change)="uploadIncidentFile($event.target.files)"
        class="upload-icon" type="file">
    </button>
  </div>

  <div class="alert alert-success successMsg" *ngIf="showSuccess">
    {{successMsg}}
  </div>
  <div class="alert alert-danger failMsg" *ngIf="showValidation">
    {{selectDropdownMsg}}
  </div>
  <div class="alert alert-danger failMsg" *ngIf="showFileValidation">
    {{fileValidationMsg}}
  </div>
  <div class="progressBar" *ngIf="showProgress" >
    <mat-progress-bar mode="determinate" value={{progressValue}} ></mat-progress-bar>
  </div>

  <div [ngClass]="isAuthorized ? 'initialPosition' : 'pushedfromUp'">
    <div *ngIf="noRecordsShow" class="records">{{noRecords}}</div>
    <div class="example-container mat-elevation-z8" [ngClass]="isAuthorized ? '' : 'newHeight'">
      <table mat-table [dataSource]="dataSource" *ngIf="!noRecordsShow">

        <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on the row definition" -->

        <!-- SINo Column -->
        <ng-container matColumnDef="SINo">
          <th mat-header-cell *matHeaderCellDef> SI.No </th>
          <td mat-cell *matCellDef="let element"> {{element.fileID}} </td>
        </ng-container>

        <!-- FileName Column -->
        <ng-container matColumnDef="FileName">
          <th mat-header-cell *matHeaderCellDef> File Name </th>
          <td mat-cell *matCellDef="let element" >
            <span *ngIf="element.uploadedStatus == 'Success'"> 
              <span class="filename" (click)="loadQAMatrixData(element.fileID,element.uploadedBy,element.qaMatrixStatus)">{{element.fileName}}</span> 
            </span>
            <span *ngIf="element.uploadedStatus == 'Failed'">
              <span>{{element.fileName}}</span>
            </span>
          </td>
        </ng-container>

        <!-- UploadedDate Column -->
        <ng-container matColumnDef="UploadedDate">
          <th mat-header-cell *matHeaderCellDef> Uploaded Date </th>
          <td mat-cell *matCellDef="let element"> {{element.uploadedDate}} </td>
        </ng-container>

        <!-- UploadedBy Column -->
        <ng-container matColumnDef="UploadedBy">
          <th mat-header-cell *matHeaderCellDef> Uploaded By </th>
          <td mat-cell *matCellDef="let element"> {{element.uploadedBy}} </td>
        </ng-container>

        <!-- IncidentType Column -->
        <ng-container matColumnDef="IncidentType">
          <th mat-header-cell *matHeaderCellDef> Incident Type </th>
          <td mat-cell *matCellDef="let element"> {{element.incidentType}} </td>
        </ng-container>

        <!-- Status Column -->
        <ng-container matColumnDef="Status">
          <th mat-header-cell *matHeaderCellDef>Status</th>
          <td mat-cell *matCellDef="let element"> {{element.uploadedStatus}} </td>
        </ng-container>

        <!-- Dashboard Column -->
        <ng-container matColumnDef="Dashboard">
          <th mat-header-cell *matHeaderCellDef> Dashboard </th>
          <td mat-cell *matCellDef="let element" >
            <span *ngIf="element.qaMatrixStatus === 'Done'"> 
              <span class="filename" (click)="loadDashboard(element.fileID)">View Dashboard</span> 
            </span>
            <span *ngIf="element.qaMatrixStatus !== 'Done'">
              <span>--NA--</span>
            </span>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>
</div>