<div class="lossTable"> 
  <mat-table [dataSource]="dataSource" 
             multiTemplateDataRows>

    <!-- COLUMNS AND ROWS-->
    <ng-container *ngFor="let key of displayedColumns"
                  [matColumnDef]="key">
      <mat-header-cell *matHeaderCellDef>{{ key.includes('Icon') ? 'Level 0' : key }}</mat-header-cell>

      <mat-cell *matCellDef="let element; let i = dataIndex"> 
        <span class='level0-hover' *ngIf="!key.includes('Icon'); else icon"  (click)='addRowLossTree(element)'>{{ element[referenceId] }}</span>     
        <span class='level0-hover' *ngIf="key.includes('Icon') icon"  (click)='addRowLossTree(element)'>{{ element[referenceId] }}</span>     
        <ng-template #icon>
          <mat-icon *ngIf="key === 'expandIcon'" 
                    [class.active]="expanded[i] == true"
                    (click)="toggleExpandableSymbol(i)">play_arrow</mat-icon>       
        </ng-template>   
        
      </mat-cell>
    </ng-container>

     <!-- EXPANDABLE ROW -->
    <ng-container matColumnDef="expandedDetail">
      <mat-cell *matCellDef="let element; let i = dataIndex">
      <div *ngIf="!this.expanded[i] == true"
           class="width-100">
        <app-has-relative-table [relatives]="element.lossTreeParent" 
        (btnEnable)="emitTableData($event)"
        (emitLossTreeData)="emitLossTreeDataParent($event)"></app-has-relative-table>  
      </div>

    </mat-cell>
  </ng-container>
  <mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></mat-header-row>

  <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

  <mat-row class="expandable-row"
           *matRowDef="let row; columns: ['expandedDetail'];">
  </mat-row>

  </mat-table>
</div>