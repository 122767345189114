export class LossTree {
    public aggregation: string;
    public explanationExamples: string;
    public level0: string;
    public level1: string;
    public level2: string;
    public level3: string;
    public level4: string;
    public level5: string;
    public linkwithT5Z: string;
    public lossTreeID: string;
    public severityGrade: string;
}

export class LossTreeParent {
    public level0: string;
    public lossTreeParent: Array<LossTree>;
}
