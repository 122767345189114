<div class="flexed">
  <button class="btn primary" matStepperPrevious (click)="goBackToDetection()">Prev</button>
  <button style="margin-left:10px" class="btn primary" *ngIf="isAuthorized" matStepperNext (click)="saveQAmatrix()">Submit</button>
  <button style="margin-left:10px" class="btn primary" *ngIf="!isAuthorized" matStepperNext (click)="saveQAmatrix()">View</button>
</div>
<div class="example-container mat-elevation-z8">
    <table mat-table [dataSource]="dataSource">

        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->
      
        <!-- productionDate Column -->
        <ng-container matColumnDef="productionDate">
          <th mat-header-cell *matHeaderCellDef>{{production}}<br/>{{date}}</th>
          <td mat-cell *matCellDef="let element"> {{element.productionDate}} </td>
        </ng-container>
     
        <!-- formatAggregation Column -->
        <ng-container matColumnDef="formatAggregation">
          <th mat-header-cell *matHeaderCellDef>{{formatAggregation}}</th>
          <td mat-cell *matCellDef="let element"> {{element.formatAggregation}} </td>
        </ng-container>
      
        <!-- airswebId Column -->
        <!-- <ng-container matColumnDef="airswebId">
          <th mat-header-cell *matHeaderCellDef>{{airswebId}}</th>
          <td mat-cell *matCellDef="let element"> {{element.airswebId}} </td>
        </ng-container>          -->
    
        <!-- Defect Priority index Column -->
        <ng-container matColumnDef="defectPriorityIndex">
          <th mat-header-cell *matHeaderCellDef>{{defectPriorityIndex}} <br>{{formulae}} &#931;Di</th>
          <td mat-cell *matCellDef="let element"> {{element.dpi}} </td>
        </ng-container>

         <!--  Total DPI column -->
        <ng-container matColumnDef="vstotalDPI">
            <th mat-header-cell *matHeaderCellDef>{{vstotalDPI}}</th>
            <td mat-cell *matCellDef="let element"> {{element.calc}}%</td>
          </ng-container>

           <!-- Cummulative Column -->
          <ng-container matColumnDef="cumulativeDPI">
            <th mat-header-cell *matHeaderCellDef>{{cummulativeDPI}}</th>
            <td mat-cell *matCellDef="let element"> {{element.cummulativeDPI}}%</td>
          </ng-container>

            <!-- DPI defect class Column -->
            <ng-container matColumnDef="dpiDefectClass">
                <th mat-header-cell *matHeaderCellDef>{{dpiDefectClass}}</th>
                <td mat-cell *matCellDef="let element"> {{element.dpiClass}} </td>
            </ng-container>

         

            <!--  PDCA Column -->
            <ng-container matColumnDef="pdca">
                <th mat-header-cell *matHeaderCellDef  class="padding-cols">{{pdca}}</th>
                <td mat-cell *matCellDef="let element; let i = index;" [ngClass]="isAuthorized ? '' : 'readOnly'"> 
                  <div class="dropdown">
                    <button type="button" [ngClass]="restrictEdit ? 'readOnly': ''" class="btn btn-primary dropdown-toggle" (focusout)="focusOutPdca()"
                      data-toggle="dropdown" (click)="pdcaDefaultValue(i)">
                      {{element.pdca}}
                      <span [ngClass]="showPdca && rowID == i? 'fa fa-angle-up arrow-position' : 'fa fa-angle-down arrow-position'"></span>
                      
                    </button>
                    <ul class="dropdown-menu">
                      <li>
                      <p class="dropdown-item" title="{{item}}" href="#" *ngFor="let item of pdcaValues" (click)="selectedPdca(item, i)">
                        {{item}}</p></li>
                      </ul>
                  </div>
                </td>
              </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
  </div>

