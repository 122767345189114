<div class="flexed">
  <button class="btn btn-primary" matStepperPrevious (click)="goBackToFreq()">Prev</button>
  <button style="margin-left:10px" class="btn btn-primary" [ngClass]="enableToNavigate ? '': 'disable'" matStepperNext
    (click)="saveCost()">Next</button></div>
<div class="example-container mat-elevation-z8" [formGroup]="qaForm">
  <table mat-table [dataSource]="dataSource">

    <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

    <!-- productionDate Column -->
    <ng-container matColumnDef="productionDate">
      <th mat-header-cell *matHeaderCellDef>{{production}}<br />{{date}}</th>
      <td mat-cell *matCellDef="let element"> {{element.productionDate}} </td>
    </ng-container>

    <!-- formatAggregation Column -->
    <ng-container matColumnDef="formatAggregation">
      <th mat-header-cell *matHeaderCellDef>{{formatAggregation}}</th>
      <td mat-cell *matCellDef="let element"> {{element.formatAggregation}} </td>
    </ng-container>

    <!-- airswebId Column -->
    <!-- <ng-container matColumnDef="airswebId">
          <th mat-header-cell *matHeaderCellDef>{{airswebId}}</th>
          <td mat-cell *matCellDef="let element"> {{element.airswebId}} </td>
        </ng-container>          -->

    <!-- cost of 1 piece Column -->
    <ng-container matColumnDef="cost1piece" formGroupName="costDetails">
      <th mat-header-cell *matHeaderCellDef>{{costof1piece}}</th>
      <td mat-cell *matCellDef="let element; let i = index" [ngClass]="isAuthorized ? '' : 'readOnly'">
        <input tabindex="-1" step="1" type="textbox" formControlName="costof1pc" required matInput value={{element.costOf1Piece}}
          (focusout)="updateCostCalculationFields(element,$event,i)" appTwoDigitDecimaNumber >
      </td>
    </ng-container>

    <!-- Total defect cost Column -->
    <ng-container matColumnDef="totaldefectcost">
      <th mat-header-cell *matHeaderCellDef>{{totalDefectCost}}</th>
      <td mat-cell *matCellDef="let element"> {{element.totalDefectCost}} </td>
    </ng-container>

    <!-- Total cost Column -->
    <ng-container matColumnDef="%fromtotalcost">
      <th mat-header-cell *matHeaderCellDef>{{totalCost}}</th>
      <td mat-cell *matCellDef="let element"> {{element.percentTotalCost}}</td>
    </ng-container>

    <!-- Cost Score Column -->
    <ng-container matColumnDef="costscore">
      <th mat-header-cell *matHeaderCellDef>{{costScore}}</th>
      <td mat-cell *matCellDef="let element"> {{element.costScore}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

</div>