import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dummy',
  templateUrl: './dummy.component.html',
  styleUrls: ['./dummy.component.scss']
})

// Component loads to reload loss tree page of the application
export class DummyComponent implements OnInit {
  enableSaveBtn = false;
  clickedLevel1 = false;
  constructor() { }

  ngOnInit() {
  }

}
