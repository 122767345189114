import { Component, OnInit } from '@angular/core';
import { LANDINGPAGE_HEADER, CARDITEMLIST } from '../common/constants/labels';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
import { AuthenticationService } from '../common/services/authentication.service';
import { Role } from '../common/models/role';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})

// Component loads the landing page of the application
export class LandingPageComponent implements OnInit {

  headerName = LANDINGPAGE_HEADER;
  cardItemlist = CARDITEMLIST;
  userName;
  name;
  role;
  loading = true;
  // Constructor of the page
  constructor(private adalSvc: MsAdalAngular6Service) {
  }
  ngOnInit() {
    this.loginInfo();
  }
  // method to set logged in information
  loginInfo() {
    this.name = localStorage.getItem('UserName');
    this.userName = localStorage.getItem('UserEmailAddress');
    setTimeout(() => {
      this.loading = false;
    }, 500);
    if (localStorage.getItem('role') && localStorage.getItem('role').length > 0) {
      this.role = '(' + localStorage.getItem('role') + ')';
    }
  }
  // method for logout
  logout() {
    this.adalSvc.logout();
    localStorage.clear();
  }
}
