import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './common/components/header/header.component';
import { SideMenuBarComponent } from './common/components/side-menu-bar/side-menu-bar.component';
import { SideMenuBarQAComponent } from './common/components/side-menu-bar-copy/side-menu-barqa.component';
import { MaterialModule } from './material.module';
import { ErrorInterceptor } from './common/interceptors/error.interceptor';
import { MockApiInterceptor } from './common/interceptors/mock-api.interceptor';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { FactoryInformationComponent, DialogSuccessComponent } from './factory-information/factory-information.component';
import { IncidentUploadComponent, IncidentUploadErrorPopupComponent } from './incident-upload/incident-upload.component';
import { QaMatrixCreationComponent, DashBoardDialogPopUpComponent } from './qa-matrix-creation/qa-matrix-creation.component';
import { DefectModeTabComponent } from './qa-matrix-creation/defect-mode-tab/defect-mode-tab.component';
import { FrequencyTabComponent } from './qa-matrix-creation/frequency-tab/frequency-tab.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { StandardInstructionComponent } from './standard-instruction/standard-instruction.component';
import { CostTabComponent } from './qa-matrix-creation/cost-tab/cost-tab.component';
import { DetectionPointTabComponent } from './qa-matrix-creation/detection-point-tab/detection-point-tab.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DpiCalPdcaComponent } from './qa-matrix-creation/dpi-cal-pdca/dpi-cal-pdca.component';
import { DummyComponent } from './common/components/dummy/dummy.component';
import { MsAdalAngular6Module, AuthenticationGuard } from 'microsoft-adal-angular6';
import { environment } from '../environments/environment';
import { DpiParetoChartComponent } from './dashboard/dpi-pareto-chart/dpi-pareto-chart.component';
import { PieChartComponent } from './dashboard/pie-chart/pie-chart/pie-chart.component';
import { PieChartPdcaComponent } from './dashboard/pie-chart/pie-chart-pdca/pie-chart-pdca.component';
import { ErrorPageComponent } from './common/components/error-page/error-page.component';
import { SideMenuBarDashboardComponent } from './common/components/side-menu-bar-dashboard/side-menu-bardashboard.component';
import { LossTreeComponent, AddLossTreeDialogPopUpComponent, DialogOverviewExampleDialogComponent } from './loss-tree/loss-tree.component';
import { TwoDigitDecimaNumberDirective } from './two-digit-decima-number.directive';
import { AdalService, AdalGuard } from 'adal-angular4';
import { TableRowComponent } from './loss-tree/table-row/table-row.component';
import { HasRelativeTableComponent } from './loss-tree/has-relative-table/has-relative-table.component';
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SideMenuBarComponent,
    LandingPageComponent,
    FactoryInformationComponent,
    IncidentUploadComponent,
    QaMatrixCreationComponent,
    DefectModeTabComponent,
    FrequencyTabComponent,
    DashboardComponent,
    StandardInstructionComponent,
    CostTabComponent,
    DetectionPointTabComponent,
    DpiCalPdcaComponent,
    SideMenuBarQAComponent,
    DialogOverviewExampleDialogComponent,
    DummyComponent,
    DpiParetoChartComponent,
    DashBoardDialogPopUpComponent,
    DialogSuccessComponent,
    IncidentUploadErrorPopupComponent,
    PieChartComponent,
    PieChartPdcaComponent,
    ErrorPageComponent,
    SideMenuBarDashboardComponent,
    LossTreeComponent,
    TwoDigitDecimaNumberDirective,
    TableRowComponent,
    HasRelativeTableComponent,
    AddLossTreeDialogPopUpComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MsAdalAngular6Module.forRoot({
      tenant: environment.tenant,
      clientId: environment.clientId,
      // redirectUri: window.location.origin,
      redirectUri: environment.endpoints,
      // endpoints: environment.endpoints,
      navigateToLoginRequestUrl: false,
      cacheLocation: 'sessionStorage'
      // resourceUrl:  'https://graph.microsoft.com'
    })
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: MockApiInterceptor, multi: true },
    AuthenticationGuard,
    AdalService,
    AdalGuard
  ],
  bootstrap: [AppComponent],
  entryComponents: [AppComponent, DialogOverviewExampleDialogComponent, DashBoardDialogPopUpComponent,
    DialogSuccessComponent, IncidentUploadErrorPopupComponent, AddLossTreeDialogPopUpComponent]
})
export class AppModule { }
