import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  PRODUCTION, DATE, FORMAT_AGGREGATION, AIRSWEB_ID,
  BLOCKEDPRODUCT_NO, TOTAL_PRODUCTION_AMOUNT, AVERAGE_BATCH, UOM,
  PERCENTAGE_AFFECTEDPRODUCT, FREQUENCY_SCORE, FREQUENCYCOLUMNSNAME
} from '../../common/constants/labels';
import { FREQUENCY_TABLE_DATA } from './frequencyTabledata';
import { FrequencyModeTab } from 'src/app/common/models/qa-matrix-tab';
import { QaMatrixDataModel } from 'src/app/common/models/qa-matrix-save';
import { ApiService } from '../../common/services/api/api.service';
import { FormGroup } from '@angular/forms';
import { Role } from 'src/app/common/models/role';

// Interface for the frequency table column names
export interface FrequencyTableData {
  productionDate: string;
  formatAggregation: string;
  blockedproduct: number;
  ProductionAmount: number;
  UoM: string;
  affectedProduct: number;
  frequencyScore: number;
}

@Component({
  selector: 'app-frequency-tab',
  templateUrl: './frequency-tab.component.html',
  styleUrls: ['./frequency-tab.component.scss']
})

// Component displays the frquency tab of QA Matrix Creation menu
export class FrequencyTabComponent implements OnInit {
  date = DATE;
  production = PRODUCTION;
  formatAggregation = FORMAT_AGGREGATION;
  airswebId = AIRSWEB_ID;
  blockedproduct = BLOCKEDPRODUCT_NO;
  ProdAmount = TOTAL_PRODUCTION_AMOUNT;
  avgBatch = AVERAGE_BATCH;
  UoM = UOM;
  affectedProduct = PERCENTAGE_AFFECTEDPRODUCT;
  freqScore = FREQUENCY_SCORE;
  freqData: Array<FrequencyModeTab> = [];
  displayedColumns: any;
  dataSource: any;
  ELEMENT_DATA: FrequencyTableData[] = FREQUENCY_TABLE_DATA;
  @Input() dataSourceInput: any;
  @Input() qaMatrixData: any;
  @Input() qaMatrixStatusValue: any;
  @Input() allTabData: any;
  saveQaMatrix: Array<QaMatrixDataModel> = [];
  @Output() pushSaveData: EventEmitter<Array<QaMatrixDataModel>> = new EventEmitter();
  @Output() changeImageStatus: EventEmitter<any> = new EventEmitter();
  @Output() recalculateFreqTab: EventEmitter<any> = new EventEmitter();
  setDefaultSave = false;
  constructor() { }
  @Input() qaForm: FormGroup;
  AvgBatchAmount = '';
  rowCount = 0;
  enableToNavigate = false;
  restrictEdit = false;
  role;
  showFieldValidation = false;
  ngOnInit() {
    this.displayedColumns = FREQUENCYCOLUMNSNAME;
    // this.dataSource = this.ELEMENT_DATA;
    /* istanbul ignore next */
    this.dataSourceInput.forEach((e) => {
      const d = new Date(e.dateOccurredOn);
      this.freqData.push({
        productionDate: this.getFormattedDate(d),
        formatAggregation: e.subCategory + ' ' + e.product,
        noOfBlockedProduct: e.numberOfBlockedProduct,
        totalProdAmount: e.totalProductionAmount,
        Uom: e.uoM,
        percOfAffectedProd: e.percentageEffectedProduct ? e.percentageEffectedProduct + '%' : e.percentageEffectedProduct,
        freqScore: e.frequencyScore
      });
      /* istanbul ignore if */
      if (e.totalProductionAmount !== null && (+e.numberOfBlockedProduct <= +e.totalProductionAmount)) {
        this.rowCount++;
      }
    });
    for (let i = 0; i < this.dataSourceInput.length; i++) {
      /* istanbul ignore if */
      if ((this.dataSourceInput[i].totalProductionAmount === null) || (+this.dataSourceInput[i].numberOfBlockedProduct > +this.dataSourceInput[i].totalProductionAmount)) {
        this.enableToNavigate = false;
        break;
      } else {
          this.enableToNavigate = true;
      }
    }
    /* istanbul ignore next */
    this.qaFormstatus();
    this.dataSource = this.freqData;
    this.saveQaMatrix = this.qaMatrixData;
  }
  // method for formatting date
  getFormattedDate(date) {
    if (date) {
      const year = date.getFullYear();
      let month = (1 + date.getMonth()).toString();
      month = month.length > 1 ? month : '0' + month;
      let day = date.getDate().toString();
      day = day.length > 1 ? day : '0' + day;
      return day + '-' + month + '-' + year;
    }
  }
  // method to update average batch amount field and for calculating frequency index
  /* istanbul ignore next */
  updateCalculationFields(elementValue, fieldValue, indexVal, e) {
    this.AvgBatchAmount = fieldValue.target.value;
    const avgBatchIntValue = +this.AvgBatchAmount;
    const noOfBlockedProductInt = +elementValue.noOfBlockedProduct;

    if (+this.AvgBatchAmount !== +elementValue.totalProdAmount) {
      if (+this.AvgBatchAmount > 0) {
        if ((noOfBlockedProductInt <= avgBatchIntValue)) {
          if (+elementValue.noOfBlockedProduct > +elementValue.totalProdAmount) {
            this.rowCount++;
          }
        } else if ((+elementValue.totalProdAmount > 0) && (+this.AvgBatchAmount > 0)) {
          this.rowCount--;
        }
      } else if (+elementValue.totalProdAmount > 0) {
        this.rowCount--;
      }
      if (this.rowCount >= this.dataSource.length) {
        if (+elementValue.noOfBlockedProduct <= +this.AvgBatchAmount) {
          this.enableToNavigate = true;
        } else {
          this.enableToNavigate = false;
        }
        if (this.rowCount > this.dataSource.length) {
          this.rowCount = this.dataSource.length;
        }
      } else {
        this.enableToNavigate = false;
      }
    }
    if (+this.AvgBatchAmount > 0) {
      elementValue.percOfAffectedProd = ((elementValue.noOfBlockedProduct / fieldValue.target.value) * 100).toFixed(3);
      if (elementValue.percOfAffectedProd !== null && elementValue.percOfAffectedProd !== 'Infinity') {
        if (elementValue.percOfAffectedProd > 6.68 && elementValue.percOfAffectedProd <= 100) {
          elementValue.freqScore = 5;
        }
        if (elementValue.percOfAffectedProd > 0.621 && elementValue.percOfAffectedProd <= 6.68) {
          elementValue.freqScore = 4;
        }
        if (elementValue.percOfAffectedProd > 0.0233 && elementValue.percOfAffectedProd <= 0.621) {
          elementValue.freqScore = 3;
        }
        if (elementValue.percOfAffectedProd > 0.00034 && elementValue.percOfAffectedProd <= 0.0233) {
          elementValue.freqScore = 2;
        }
        if (elementValue.percOfAffectedProd >= 0 && elementValue.percOfAffectedProd <= 0.00034) {
          elementValue.freqScore = 1;
        }
        elementValue.totalProdAmount = fieldValue.target.value;
        elementValue.percOfAffectedProd = elementValue.percOfAffectedProd;
        this.qaMatrixData.qaMatrixIncidents[indexVal].totalProductionAmount = fieldValue.target.value;
        this.qaMatrixData.qaMatrixIncidents[indexVal].percentageEffectedProduct = elementValue.percOfAffectedProd;
        this.qaMatrixData.qaMatrixIncidents[indexVal].frequencyScore = elementValue.freqScore;
        this.saveQaMatrix = this.qaMatrixData;

        elementValue.percOfAffectedProd = elementValue.percOfAffectedProd + '%';
        this.recalculateFreqTab.emit('2');
      }
    /* istanbul ignore else */
    } else {
      elementValue.totalProdAmount = fieldValue.target.value;
      elementValue.percOfAffectedProd = '';
      elementValue.freqScore = '';
      /* istanbul ignore next */
      this.qaMatrixData.qaMatrixIncidents[indexVal].totalProductionAmount = fieldValue.target.value;
      this.qaMatrixData.qaMatrixIncidents[indexVal].percentageEffectedProduct = elementValue.percOfAffectedProd;
      this.qaMatrixData.qaMatrixIncidents[indexVal].frequencyScore = elementValue.freqScore;
      this.saveQaMatrix = this.qaMatrixData;
      this.recalculateFreqTab.emit('2');
    }

  }
  // making form valid to navigate next tab
  qaFormstatus() {
    return this.qaForm.get('frequencyDetails').status;
  }
  // method to save changes and navigate to next tab
  saveFrequency() {
    this.pushSaveData.emit(this.saveQaMatrix);
    this.qaFormstatus();
    if (this.dataSourceInput[0].frequencyScore > 0) {
      this.qaFormstatus();
    }
    this.changeImageStatus.emit('frequency/1');
  }
  // method invokes when user will click prev button
  goBackToDefect() {
    this.changeImageStatus.emit('defect/2');
  }
  // Authorization
  get isAuthorized() {
    this.role = localStorage.getItem('role');
    if (this.role === Role.qualityManager || this.role === Role.qualityEngineer || this.role === Role.globalAdmin || this.role === Role.ITSupport) {
      return true;
    } else {
      return false;
    }
  }
}
