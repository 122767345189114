import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpEvent } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { FILEUPLOAD, LOSSTREEEDIT_GETFILES, QAMATRIXDATA, MACHINELIST } from '../common/constants/labels';
import { environment } from '../../environments/environment';
import { ApiService } from '../common/services/api/api.service';
import { UploadedIncidentFileDetails } from '../common/models/incident-upload';
import { Router } from '@angular/router';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';


@Injectable({
  providedIn: 'root'
})
// service class for incident upload page of the application
export class IncidentUploadService {
  fileUploadURL = environment.APISETTINGS + FILEUPLOAD;
  getFileDetailsURL = environment.APISETTINGS + LOSSTREEEDIT_GETFILES;
  fileID: string;
  loading = true;

  constructor(private service: ApiService, private router: Router, private adalSvc: MsAdalAngular6Service) { }
  // service method for uploading file
  /* istanbul ignore next */
  public postFile(fileToUpload: File, incidentType: string): Observable<HttpEvent<any>> {
    const userId = this.adalSvc.userInfo.profile.name;
    const finalURl = this.fileUploadURL + incidentType;
    return this.service.inputXML(fileToUpload, incidentType, finalURl);
  }
  // service method to get file details
  public getIncidentFiles(): Observable<Array<UploadedIncidentFileDetails>> {
    return this.service.get(this.getFileDetailsURL);
  }
  // service method to get qa matrix data
  /* istanbul ignore next */
  public getQAMatrixData(id: number): Observable<any> {
    return this.service.get(environment.APISETTINGS + QAMATRIXDATA + id);
  }
  // service method to get qamatrix data by file id
  /* istanbul ignore next */
  public getQAMatrixDataFileId(id: number): Observable<any> {
    return this.service.get(environment.APISETTINGS + QAMATRIXDATA + id);
  }
  // service method to get list of machines by userid
  /* istanbul ignore next */
  public getMachineListByUserId(): Observable<any> {
    return this.service.get(environment.APISETTINGS + MACHINELIST);
  }
  // service method to navigate dashboard tab
  /* istanbul ignore next */
  public getQAMatrixDataFileIdDashboard(id: number): Observable<any> {
    return this.service.get(environment.APISETTINGS + QAMATRIXDATA + id);
  }
}
