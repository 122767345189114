import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  PRODUCTION, DATE, FORMAT_AGGREGATION, AIRSWEB_ID,
  OCCURANCE, DETECTIONPOINT, DETECTABILITY, MS, DETECTIONPOINTCOLUMNSNAME,
  VALUES, FOURMS, DETECTIONPOINTVALUES, DETECTIONTYPEVALUES
} from '../../common/constants/labels';
import { FREQUENCY_TABLE_DATA } from '../frequency-tab/frequencyTabledata';
import { DetectionPointTab } from 'src/app/common/models/qa-matrix-tab';
import { QaMatrixDataModel } from 'src/app/common/models/qa-matrix-save';
import { DetectionTabService } from './detection-tab.service';
import { FormGroup } from '@angular/forms';
import { Role } from 'src/app/common/models/role';

@Component({
  selector: 'app-detection-point-tab',
  templateUrl: './detection-point-tab.component.html',
  styleUrls: ['./detection-point-tab.component.scss']
})
// Component displays the defect mode tab from QA MAtrix creation menu item
export class DetectionPointTabComponent implements OnInit {
  date = DATE;
  production = PRODUCTION;
  formatAggregation = FORMAT_AGGREGATION;
  airswebId = AIRSWEB_ID;
  occurance = OCCURANCE;
  ms = MS;
  detectionpoint = DETECTIONPOINT;
  detectability = DETECTABILITY;
  showOccurance = false;
  activeList = VALUES;
  Ms = FOURMS;
  displayedColumns: any;
  dataSource: any;
  showMs = false;
  rowID;
  showDetectionPoint = false;
  showDetectability = false;
  makeCriticalComponentInActive = true;
  showDetectype = false;
  detectionData: Array<DetectionPointTab> = [];
  machineNames = [];
  detectionTypes = DETECTIONTYPEVALUES;
  @Input() dataSourceInput: any;
  @Input() qaMatrixData: any;
  @Input() machineDetails: any;
  @Input() qaMatrixStatusValue: any;
  @Input() allTabData: any;
  saveQaMatrix: Array<QaMatrixDataModel> = [];
  @Output() pushSaveData: EventEmitter<Array<QaMatrixDataModel>> = new EventEmitter();
  @Output() changeImageStatus: EventEmitter<any> = new EventEmitter();
  @Input() qaForm: FormGroup;
  @Input() costTabStatus: any;
  @Input() freqTabStatus: any;
  enableToNavigate = false;
  restrictEdit = false;
  occHasValue = false; msHasValue = false; dpHasValue = false; detectHasValue = false; typeHasValue = false;
  occRowCount = 0; msRowCount = 0; detPointRowCount = 0; detectRowCount = 0; typeRowCount = 0;
  role;
  constructor(private service: DetectionTabService) { }
  /* istanbul ignore next */
  ngOnInit() {
    this.displayedColumns = DETECTIONPOINTCOLUMNSNAME;
    /* istanbul ignore next */
    this.dataSourceInput.forEach((e) => {
      const d = new Date(e.dateOccurredOn);
      this.detectionData.push({
        productionDate: this.getFormattedDate(d),
        formatAggregation: e.subCategory + ' ' + e.product,
        occurance: e.occurance == null ? 'Select' : e.occurance,
        ms: e.fourMs == null ? 'Select' : e.fourMs,
        detectionPoint: e.detectionPoint == null ? 'Select' : e.detectionPoint,
        detectionType: e.detectionType == null ? 'Select' : e.detectionType,
        detectability: e.detectibility,
        criticalComponent: e.criticalComponent
      });
    });
    for (let i = 0; i < this.dataSourceInput.length; i++) {
      if ((this.dataSourceInput[i].detectionPoint === 'Select' || this.dataSourceInput[i].detectionPoint === null) && (this.dataSourceInput[i].detectionType === 'Select' || this.dataSourceInput[i].detectionType === null) && (this.dataSourceInput[i].occurance === 'Select'
      || this.dataSourceInput[i].occurance === null) && (this.dataSourceInput[i].fourMs === 'Select' || this.dataSourceInput[i].fourMs === null)) {
        this.enableToNavigate = false;
        break;
      } else {
        if (this.dataSourceInput[i].detectionPoint !== 'Select') {
          this.detPointRowCount++;
        }
        if (this.dataSourceInput[i].detectionType !== 'Select') {
          this.typeRowCount++;
        }
        if (this.dataSourceInput[i].occurance !== 'Select') {
          this.occRowCount++;
        }
        if (this.dataSourceInput[i].fourMs !== 'Select') {
          this.msRowCount++;
        }
      }
    }
    if (this.occRowCount === this.dataSourceInput.length && this.msRowCount === this.dataSourceInput.length &&
      this.detPointRowCount === this.dataSourceInput.length && this.typeRowCount === this.dataSourceInput.length) {
      this.enableToNavigate = true;
      this.occHasValue = true;
      this.msHasValue = true;
      this.dpHasValue = true;
      this.typeHasValue = true;
    } else {
      if (this.occRowCount === this.dataSourceInput.length) {
        this.occHasValue = true;
      }
      if (this.msRowCount === this.dataSourceInput.length) {
        this.msHasValue = true;
      }
      if (this.detPointRowCount === this.dataSourceInput.length) {
        this.dpHasValue = true;
      }
      if (this.typeRowCount === this.dataSourceInput.length) {
        this.typeHasValue = true;
      }
      this.enableToNavigate = false;
    }
    this.dataSource = this.detectionData;
    this.machineDetails.forEach((e) => {
      this.machineNames.push(e.name);
    });
    if (this.qaMatrixStatusValue === 'Done') {
      this.occRowCount = this.dataSource.length;
      this.msRowCount = this.dataSource.length;
      this.detPointRowCount = this.dataSource.length;
      this.detectRowCount = this.dataSource.length;
      this.typeRowCount = this.dataSource.length;
      this.occHasValue = true;
      this.msHasValue = true;
      this.dpHasValue = true;
      this.typeHasValue = true;
    }
  }
  // method for occurance dropdown
  /* istanbul ignore next */
  selectedOccurance(value, indexOcc) {
    this.rowID = indexOcc;
    this.showOccurance = false;
    if (event) {
      event.preventDefault();
    }
    if (this.qaMatrixStatusValue !== 'Done') {
      if (this.dataSource[indexOcc].occurance === 'Select') {
        this.occRowCount++;
      }
      if (this.occRowCount < this.dataSource.length) {
        this.occHasValue = false;
      } else {
        this.occHasValue = true;
      }
    } else {
      this.occHasValue = true;
    }
    this.dataSource[indexOcc].occurance = value;
    this.storeAllData();
    if (this.occHasValue === true && this.msHasValue === true && this.dpHasValue === true && this.typeHasValue === true) {
      this.enableToNavigate = true;
    } else {
      this.enableToNavigate = false;
    }
  }
  // method to display the value in occurance dropdown
  occuranceDefaultValue(val) {
    this.rowID = val;
    this.showOccurance = !this.showOccurance;
  }
  // method to toggle angle arrow (up/down) for occurance dropdown
  focusOutOccurance() {
    this.showOccurance = false;
  }
  // method for ms dropdown
  /* istanbul ignore next */
  selectedMs(value, indexMs) {
    this.rowID = indexMs;
    this.showMs = false;
    if (event) {
      event.preventDefault();
    }
    if (value === 'Machine') {
      this.makeCriticalComponentInActive = false;
    } else {
      this.makeCriticalComponentInActive = true;
    }
    if (this.qaMatrixStatusValue !== 'Done') {
      if (this.dataSource[indexMs].ms === 'Select') {
        this.msRowCount++;
      }
      if (this.msRowCount < this.dataSource.length) {
        this.msHasValue = false;
      } else {
        this.msHasValue = true;
      }
    } else {
      this.msHasValue = true;
    }
    this.dataSource[indexMs].ms = value;
    this.storeAllData();
    if (this.occHasValue === true && this.msHasValue === true && this.dpHasValue === true && this.typeHasValue === true) {
      this.enableToNavigate = true;
    } else {
      this.enableToNavigate = false;
    }
  }
  // method to display the value in ms dropdown
  msDefaultValue(val) {
    this.rowID = val;
    this.showMs = !this.showMs;
  }
  // method to toggle angle arrow (up/down) for ms dropdown
  focusOutMs() {
    this.showMs = false;
  }

  // Detection point dropdown
  /* istanbul ignore next */
  selectedDetectionPoint(value, index, el) {
    this.rowID = index;
    this.showDetectionPoint = false;
    if (event) {
      event.preventDefault();
    }
    if (this.qaMatrixStatusValue !== 'Done') {
      if (this.dataSource[index].detectionPoint === 'Select') {
       this.detPointRowCount++;
      }
      if (this.detPointRowCount < this.dataSource.length) {
        this.dpHasValue = false;
      } else {
        this.dpHasValue = true;
      }
    } else {
      this.dpHasValue = true;
    }
    this.dataSource[index].detectionPoint = value;
    this.storeAllData();
    if (this.occHasValue === true && this.msHasValue === true && this.dpHasValue === true && this.typeHasValue === true) {
      this.enableToNavigate = true;
    } else {
      this.enableToNavigate = false;
    }
  }
  focusOutDetectionType() {
    this.showDetectype = false;
  }
  // method to display the value in detection point dropdown
  detectionPointDefaultValue(val) {
    this.rowID = val;
    this.showDetectionPoint = !this.showDetectionPoint;
  }
  // method to toggle angle arrow (up/down) for detection point dropdown
  focusOutDetectionPoint() {
    this.showDetectionPoint = false;
  }
  // select Detection Type
  /* istanbul ignore next */
  selectedDetectionType(value, indexOcc) {
    this.rowID = indexOcc;
    this.showDetectype = false;
    if (event) {
      event.preventDefault();
    }
    if (this.qaMatrixStatusValue !== 'Done') {
      if (this.dataSource[indexOcc].detectionType === 'Select') {
        this.typeRowCount++;
      }
      if (this.typeRowCount < this.dataSource.length) {
        this.typeHasValue = false;
      } else {
        this.typeHasValue = true;
      }
    } else {
      this.typeHasValue = true;
    }
    this.dataSource[indexOcc].detectionType = value;
    this.storeAllData();
    if (this.occHasValue === true && this.msHasValue === true && this.dpHasValue === true && this.typeHasValue === true) {
      this.enableToNavigate = true;
    } else {
      this.enableToNavigate = false;
    }
  }
  detectionTypeDefaultType(val) {
    this.rowID = val;
    this.showDetectype = !this.showDetectype;
  }

  // method for formatting date
  getFormattedDate(date) {
    if (date) {
      const year = date.getFullYear();
      let month = (1 + date.getMonth()).toString();
      month = month.length > 1 ? month : '0' + month;
      let day = date.getDate().toString();
      day = day.length > 1 ? day : '0' + day;
      return day + '-' + month + '-' + year;
    }
  }
  // updation of critical component field
  /* istanbul ignore next */
  updateCriticalComponent(elementVal, fieldVal, indexVal) {
    let arrayCount = 0;
    this.rowID = indexVal;
    elementVal.criticalComponent = fieldVal.target.value;
    this.dataSource.forEach((e) => {
      arrayCount++;
    });
    for (let i = 0; i < arrayCount; i++) {
      this.qaMatrixData.qaMatrixIncidents[i].occurance = this.dataSource[i].occurance;
      this.qaMatrixData.qaMatrixIncidents[i].fourMs = this.dataSource[i].ms;
      this.qaMatrixData.qaMatrixIncidents[i].detectionPoint = this.dataSource[i].detectionPoint;
      this.qaMatrixData.qaMatrixIncidents[i].detectionType = this.dataSource[i].detectionType;
      this.qaMatrixData.qaMatrixIncidents[i].detectibility = this.dataSource[i].detectability;
      this.qaMatrixData.qaMatrixIncidents[i].criticalComponent = this.dataSource[i].criticalComponent;
    }
  }
  // storing all data in one array
  /* istanbul ignore next */
  storeAllData() {
    let arrayCount = 0;
    this.dataSource.forEach((e) => {
      arrayCount++;
    });
    for (let i = 0; i < arrayCount; i++) {
      this.qaMatrixData.qaMatrixIncidents[i].occurance = this.dataSource[i].occurance;
      this.qaMatrixData.qaMatrixIncidents[i].fourMs = this.dataSource[i].ms;
      this.qaMatrixData.qaMatrixIncidents[i].detectionPoint = this.dataSource[i].detectionPoint;
      this.qaMatrixData.qaMatrixIncidents[i].detectionType = this.dataSource[i].detectionType;
      this.qaMatrixData.qaMatrixIncidents[i].detectibility = this.dataSource[i].detectability;
      this.qaMatrixData.qaMatrixIncidents[i].criticalComponent = this.dataSource[i].criticalComponent;
    }
  }
  // method to save changes and navigate to next tab
  /* istanbul ignore next */
  saveDetection() {
    this.calculateDpi();
    this.saveQaMatrix = this.qaMatrixData;
    this.pushSaveData.emit(this.saveQaMatrix);
    this.changeImageStatus.emit('detectionPoint/1');
  }
  // method invokes when user will click prev button
  goBackToCost() {
    this.changeImageStatus.emit('detectionPoint/2');
  }
  // Authorization
  get isAuthorized() {
    this.role = localStorage.getItem('role');
    if (this.role === Role.qualityManager || this.role === Role.qualityEngineer || this.role === Role.globalAdmin || this.role === Role.ITSupport) {
      return true;
    } else {
      return false;
    }
  }
  // method to calculate dpi
  /* istanbul ignore next */
  calculateDpi() {
    this.allTabData = this.qaMatrixData;
    for (let i = 0; i < this.allTabData.qaMatrixIncidents.length; i++) {
      this.allTabData.qaMatrixIncidents[i].dpi = (this.allTabData.qaMatrixIncidents[i].severityIndex) * (this.allTabData.qaMatrixIncidents[i].frequencyScore)
        * (this.allTabData.qaMatrixIncidents[i].costScore) * (this.allTabData.qaMatrixIncidents[i].detectibility);
    }
    this.allTabData.qaMatrixIncidents.sort((a, b) => {
      return (+b.dpi) - (+a.dpi);
    });
    this.calculateCdpi();
  }
  // method to calculate Dpi V/S TotalDpi
  /* istanbul ignore next */
  calculateCdpi() {
    let totalDpi = 0;
    let countLength = 0;
    this.allTabData.qaMatrixIncidents.forEach((e) => {
      totalDpi += e.dpi;
      countLength++;
    });
    for (let i = 0; i < countLength; i++) {
      this.allTabData.qaMatrixIncidents[i].dpIvsTotalDPI =
        Math.trunc((this.allTabData.qaMatrixIncidents[i].dpi / totalDpi) * 100);
    }
    this.calculateCumDpi();
  }
  // method to calculate cummulative dpi
  /* istanbul ignore next */
  calculateCumDpi() {
    if (this.allTabData) {
      let num1: number;
      let num2: number;
      this.allTabData.qaMatrixIncidents[0].cumulativeDPI = this.allTabData.qaMatrixIncidents[0].dpIvsTotalDPI;
      for (let i = 1; i < this.allTabData.qaMatrixIncidents.length; i++) {
        num1 = +(this.allTabData.qaMatrixIncidents[i - 1].cumulativeDPI);
        num2 = +(this.allTabData.qaMatrixIncidents[i].dpIvsTotalDPI);
        this.allTabData.qaMatrixIncidents[i].cumulativeDPI = num1 + num2;
      }
      for (let i = 0; i < this.allTabData.qaMatrixIncidents.length; i++) {
        if (this.allTabData.qaMatrixIncidents[i].cumulativeDPI <= 50) {
          this.allTabData.qaMatrixIncidents[i].defectClass = 'AA';
        }
        if (this.allTabData.qaMatrixIncidents[i].cumulativeDPI > 50 && this.allTabData.qaMatrixIncidents[i].cumulativeDPI <= 70) {
          this.allTabData.qaMatrixIncidents[i].defectClass = 'A';
        }
        if (this.allTabData.qaMatrixIncidents[i].cumulativeDPI > 70 && this.allTabData.qaMatrixIncidents[i].cumulativeDPI <= 90) {
          this.allTabData.qaMatrixIncidents[i].defectClass = 'B';
        }
        if (this.allTabData.qaMatrixIncidents[i].cumulativeDPI > 90) {
          this.allTabData.qaMatrixIncidents[i].defectClass = 'C';
        }
      }
      this.qaMatrixData = this.allTabData;
    }
  }
}
