<span class="loading" *ngIf="loading">
  <i class="fa fa-spinner fa-spin" style="font-size:40px;color:white"></i>
</span>
<app-header></app-header>
<app-side-menu-bardashboard></app-side-menu-bardashboard>
<div class="main-content fixed-height">
  <div class="heading">
    <label class="heading-text"><span>{{dashboard}}</span>
      <span class="dashboard-dd-position">
        <label *ngIf="!showMachinesPareto" (click)="filterAA('Machines')">DPI Score vs Machines</label>
        <label *ngIf="showMachinesPareto" (click)="loadAllData()">DPI Score vs Loss Tree</label>
      </span>
    </label>
    <div class="dropdown pull-right"  *ngIf="!showMachinesPareto">
      <button type="button" class="btn btn-primary dropdown-toggle dpi-hover" (focusout)="focusOutdefectClass()"
        data-toggle="dropdown" (click)="defectClassDefaultValue()">
        {{defaultdefectClass}}
        <span [ngClass]="showdefectClass? 'fa fa-angle-down arrow-position' : 'fa fa-angle-up arrow-position'"></span>
      </button>
      <ul class="dropdown-menu dpi-hover">
        <li>
          <p class="dropdown-item" title="{{item.value}}" href="#" *ngFor="let item of defectClassInfo" (click)="selecteddefectClass(item)">
            {{item.value}}</p>
        </li>
      </ul>
    </div>

  </div>
  <div class="dashboardbg">
   <div>
     <p>Factory: <strong>{{factoryName}}</strong></p>
     <p>Incident Type: <strong>{{incidentType}}</strong></p>
     <p>Month & Year: <strong>{{showmonth}}</strong></p>
   </div>
    <div class="dpi-section">
      <label class="dpi-heading dpi-position" *ngIf="!showMachinesPareto">{{dpi}}
        <br>{{formulae}} &#931;Di</label>
      <label class="dpi-heading dpi-position" *ngIf="showMachinesPareto">{{dpi}} <br>{{formulae}} &#931;Di</label>

      <label class="dpi-heading dpi-position1" *ngIf="!showMachinesPareto">DPI v/s LossTree</label>
      <label class="dpi-heading dpi-position1" *ngIf="showMachinesPareto">DPI v/s Machine</label>
      <div *ngIf="shownorecords" class="dpi-heading" style="position: relative;top: 100px;"> No records found.</div>
      <app-dpi-pareto-chart  class="machines-chart" [tempChildCell]="tempChildCell" [type]="type" [dataInfo]="dataInfo"
        [cummulativeDPI]="cummulativeDPI" [lossTreeAggregationFull]="lossTreeAggregationFull" *ngIf="showParetoAA && !shownorecords">
      </app-dpi-pareto-chart>
      <app-dpi-pareto-chart  class="machines-chart" [tempChildCell]="tempChildCell" [type]="type" [dataInfo]="dataInfo"
        [cummulativeDPI]="cummulativeDPI" [lossTreeAggregationFull]="lossTreeAggregationFull" *ngIf="showParetoA && !shownorecords">
      </app-dpi-pareto-chart>
      <app-dpi-pareto-chart  class="machines-chart" [tempChildCell]="tempChildCell" [type]="type" [dataInfo]="dataInfo"
        [cummulativeDPI]="cummulativeDPI" [lossTreeAggregationFull]="lossTreeAggregationFull" *ngIf="showParetoB && !shownorecords">
      </app-dpi-pareto-chart>
      <app-dpi-pareto-chart  class="machines-chart" [tempChildCell]="tempChildCell" [type]="type" [dataInfo]="dataInfo"
        [cummulativeDPI]="cummulativeDPI" [lossTreeAggregationFull]="lossTreeAggregationFull" *ngIf="showParetoC && !shownorecords">
      </app-dpi-pareto-chart>
      <app-dpi-pareto-chart  class="machines-chart" [tempChildCell]="tempChildCell" [type]="type" [cummulativeDPI]="cummulativeDPI"
        [lossTreeAggregationFull]="lossTreeAggregationFull" [dataInfo]="dataInfo" *ngIf="showPareto && !shownorecords">
      </app-dpi-pareto-chart>
      <app-dpi-pareto-chart class="machines-chart" [type]="type" [dataInfo]="dataInfo" [tempChildCell]="tempChildCell"
        [cummulativeDPI]="cummulativeDPI" [lossTreeAggregationFull]="lossTreeAggregationFull"
        *ngIf="showMachinesPareto && !shownorecords"></app-dpi-pareto-chart>
        <div class="legend-position" *ngIf="(showParetoAA || showParetoA || showParetoB || showParetoC || showPareto || showMachinesPareto) && !shownorecords">
          <canvas id="myCanvas" width="10" height="10" style="background-color:#ff0000"></canvas><span class="squareAA">AA</span>
          <canvas id="myCanvas" width="10" height="10" style="background-color:#ffc000"></canvas><span class="squareAA">A</span>                
          <canvas id="myCanvas" width="10" height="10" style="background-color:#9dc3e6"></canvas><span class="squareAA">B</span>
          <canvas id="myCanvas" width="10" height="10" style="background-color:#a9d18e"></canvas><span class="squareAA">C</span>  
        </div>
    </div>

    <div class="pdcabg">
      <div class="pdca-container">
        <p class="dpi-heading">{{mdistribution}}</p>
        <app-pie-chart [fourmsData]="fourmsData" *ngIf="showPieChart"></app-pie-chart>
      </div>
      <div class="pdca-container2">
        <p class="dpi-heading">{{pdca}}</p>
        <app-pie-chart-pdca [pdcaData]="pdcaData" *ngIf="showPieChartPdca"></app-pie-chart-pdca>
      </div>
    </div>

  </div>
</div>