import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { expandableTableRowAnimation } from '../expandable-table-row-animation';


@Component({
  selector: 'app-table-row',
  templateUrl: './table-row.component.html',
  styleUrls: ['./table-row.component.scss'],
  animations: [expandableTableRowAnimation]
})
export class TableRowComponent implements OnInit {
  @Input() dataSource: any[];
  @Input() displayedColumns: string[];
  @Input() referenceId: string;
  @Input() iconKeyReference: string;
  @Input() renderTemplate: string;
  @Output() addBtnEnabled: EventEmitter<any> = new EventEmitter();
  @Output() emitBtnEnable: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() emitLossTreeData: EventEmitter<boolean> = new EventEmitter<boolean>();
  expandedId;
  expanded = [];
  constructor() { }
  ngOnInit() {
    for (let i = 0; i < this.dataSource.length; i++) {
      this.expanded[i] = false;
    }
  }
  toggleExpandableSymbol(i: number): void {
    this.expanded[i] = !this.expanded[i];
  }
  addRowLossTree(element) {
    this.addBtnEnabled.emit(element);
  }
  emitTableData(value) {
    this.emitBtnEnable.emit(value);
  }
  emitLossTreeDataParent(element) {
    this.emitLossTreeData.emit(element);
  }
}
