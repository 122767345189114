export const DEFECT_TABLE_DATA = [
    {
        productionDate: '17-09-2019',
        productCode: 67753987,
        formatAggregation: 'DEO Roll-ons Vaseline 50ml',
        airswebId: 370545,
        description: 'VASELINE RO FRESH VISION 50ML',
        defectAggregation: 'Consumer Pack > Cleanliness > Cleanliness - Soil/Dirt/Grease > Foreign Matter contamination',
        severityGrade: 'AA'
    },
    {
        productionDate: '22-10-2019',
        productCode: 67737710,
        formatAggregation: 'DEO Spray Rexona 200ml',
        airswebId: 373139,
        description: 'RPK PD RFW AP 200ML INVS B&W CLTH DUO NL',
        defectAggregation: 'Distribution Pack / Pallet > Pallet Label > Label Data > Incorrect Data',
        severityGrade: 'C'
    }
    // {
    //     productionDate: '29-07-2019',
    //     productCode: 67638265,
    //     formatAggregation: 'DEO Spray Lynx 150ml',
    //     airswebId: 368988,
    //     description: 'RPK PD LYNX BS 150ML YOU DM UK',
    //     defectAggregation: 'Consumer Pack > Pack Damage > Damage-Crush/Dent/Crease/Tear > Dent',
    //     severityGrade: 'B'
    // },
    // {
    //     productionDate: '08-10-2019',
    //     productCode: 9171680,
    //     formatAggregation: 'DEO Spray Dove 150ml',
    //     airswebId: 372425,
    //     description: 'DFW APA 150ML ORIGINAL ATHENA 17 WE1',
    //     defectAggregation: 'Distribution Pack / Pallet > Pallet Label > Label Data > Incorrect Data',
    //     severityGrade: 'C'
    // },
    // {
    //     productionDate: '17-09-2019',
    //     productCode: 67753987,
    //     formatAggregation: 'DEO Roll-ons Vaseline 50ml',
    //     airswebId: 370545,
    //     description: 'VASELINE RO FRESH VISION 50ML',
    //     defectAggregation: 'Consumer Pack > Cleanliness > Cleanliness - Soil/Dirt/Grease > Foreign Matter contamination',
    //     severityGrade: 'AA'
    // },
    // {
    //     productionDate: '22-08-2019',
    //     productCode: 67638265,
    //     formatAggregation: 'DEO Spray Axe 150ml',
    //     airswebId: 368747,
    //     description: 'RPK PD AXE BS 150ML YOU DM FR',
    //     defectAggregation: 'Consumer Pack > Print > Print Color > Blotches',
    //     severityGrade: 'A'
    // },
    // {
    //     productionDate: '29-07-2019',
    //     productCode: 67638265,
    //     formatAggregation: 'DEO Spray Lynx 150ml',
    //     airswebId: 368988,
    //     description: 'RPK PD LYNX BS 150ML YOU DM UK',
    //     defectAggregation: 'Consumer Pack > Pack Damage > Damage-Crush/Dent/Crease/Tear > Dent',
    //     severityGrade: 'B'
    // },
    // {
    //     productionDate: '22-08-2019',
    //     productCode: 67638265,
    //     formatAggregation: 'DEO Spray Axe 150ml',
    //     airswebId: 368747,
    //     description: 'RPK PD AXE BS 150ML YOU DM FR',
    //     defectAggregation: 'Consumer Pack > Print > Print Color > Blotches',
    //     severityGrade: 'A'
    // }
];
