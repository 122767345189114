import { Component, OnInit, ViewChild, ViewContainerRef, Output, EventEmitter, Inject } from '@angular/core';
import {
  QAHEADER, FIRST_TABTITLE, SECOND_TABTITLE, THIRD_TABTITLE,
  FOURTH_TABTITLE, FIFTH_TABTITLE_DPI, FIFTH_TABTITLE_PDCA
} from '../common/constants/labels';
import { IncidentUploadService } from '../incident-upload/incident-upload.service';
import { QaMatrixDataModel } from 'src/app/common/models/qa-matrix-save';
import { QaMatrixCreationService } from './qa-matrix-creation.service';
import { MatStepper } from '@angular/material/stepper';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Role } from '../common/models/role';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-qa-matrix-creation',
  templateUrl: './qa-matrix-creation.component.html',
  styleUrls: ['./qa-matrix-creation.component.scss']
})

// Component loads the QA MAtrix creation page with different tabs
export class QaMatrixCreationComponent implements OnInit {
  @ViewChild('stepper', { read: ViewContainerRef, static: false }) stepper: MatStepper;
  @Output() steppingStatus: EventEmitter<any> = new EventEmitter();
  QAheader = QAHEADER;
  firsttabTitle = FIRST_TABTITLE;
  secondtabTitle = SECOND_TABTITLE;
  thirdtabTitle = THIRD_TABTITLE;
  fourthtabTitle = FOURTH_TABTITLE;
  fifthtabTitledpi = FIFTH_TABTITLE_DPI;
  fifthtabTitlepdca = FIFTH_TABTITLE_PDCA;
  activeFreq = false;
  activeCost = false;
  activeDetection = false;
  activeDpi = false;
  matrixData: any;
  matrixDataTab: any = [];
  machineDetails: any;
  catchSaveQaModel: Array<QaMatrixDataModel>;
  allTabData: Array<QaMatrixDataModel>;
  isLinear = false;
  defectModeSaved: FormGroup;
  qamatrixForm: FormGroup;
  qaMatrixStatusValue: any;
  role;
  costTabStatus = false;
  freqTabStatus = false;
  fetchId;
  loading = true;
  constructor(public dialog: MatDialog, private uploadService: IncidentUploadService, private qaService: QaMatrixCreationService, private route: Router, private actroute: ActivatedRoute) {}
  /* istanbul ignore next */
  ngOnInit() {
    this.fetchId = this.actroute.snapshot.paramMap.get('fileId');
    this.uploadService.getQAMatrixData(this.fetchId).subscribe((response) => {
      if (response) {
        this.loading = false;
        this.matrixData = response;
        this.matrixDataTab = this.matrixData.qaMatrixIncidents;
        this.uploadService.getMachineListByUserId().subscribe((data) => {
          if (data) {
            this.machineDetails = data.machines;
          }
        }, (error) => {
          this.loading = false;
        });
      }
    }, (error) => {
      this.loading = false;
    });
    this.qamatrixForm = new FormGroup({
      defectModeDetails: new FormGroup({
        formatValue: new FormControl(null, Validators.required)
      }),
      frequencyDetails: new FormGroup({
        avgBatchValue: new FormControl(null, Validators.required)
      }),
      costDetails: new FormGroup({
        costof1pc: new FormControl(null, Validators.required)
      }),
    });
  }
  // method fpor changing tab images
  /* istanbul ignore next */
  changeTabImage(event) {
    // will make frequency icon active on click of save button of defect tab
    if (event === 'defect/1') {
      this.activeFreq = true;
    }
    // will make defectmode icon active on click of prev button of frequency tab
    if (event === 'defect/2') {
      this.activeFreq = false;
    }
    // will make cost icon active on click of save button of frequency tab
    if (event === 'frequency/1') {
      this.activeCost = true;
    }
    // will make frequency icon active on click of prev button of cost tab
    if (event === 'frequency/2') {
      this.activeCost = false;
    }
    // will make detectibility icon active on click of save button of cost tab
    if (event === 'cost/1') {
      this.activeDetection = true;
    }
    // will make dpi icon active on click of save button of detectibility tab
    if (event === 'detectionPoint/1') {
      this.activeDpi = true;
    }
    // will make cost icon active on click of prev button of detectibility tab
    if (event === 'detectionPoint/2') {
      this.activeDetection = false;
    }
    // will make detectibility icon active on click of prev button of dpi tab
    if (event === 'dpi-cal/2') {
      this.activeDpi = false;
    }
  }
  // saving all data from all tab
  /* istanbul ignore next */
  callParentSave(saveData: Array<QaMatrixDataModel>) {
    this.allTabData = saveData;
  }
  // method will invoke when user will click on submit button
  /* istanbul ignore next */
  callParentApiSave(allTabData: Array<QaMatrixDataModel>) {
    this.qaService.postQAMatrixAllTabData(allTabData).subscribe((e) => {
      if (e.message === 'Successful update' && this.isAuthorized) {
        this.openDialogPopUp(allTabData['fileID']);
      } else {
        this.uploadService.getQAMatrixDataFileIdDashboard(allTabData['fileID']);
      }
    });

  }
  // method for opening confirmation dialog box
  /* istanbul ignore next */
  openDialogPopUp(fileId): void {
    const dialogRef = this.dialog.open(DashBoardDialogPopUpComponent, {
      data: { id: fileId }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        this.route.navigate(['/dashboard', fileId]);
      }
    });
  }

  // Authorization
  get isAuthorized() {
    this.role = localStorage.getItem('role');
    if (this.role === Role.qualityManager || this.role === Role.qualityEngineer || this.role === Role.globalAdmin || this.role === Role.ITSupport) {
      return true;
    } else {
      return false;
    }
  }
  recalculateCostTab(event) {
    if (event === '1' || this.freqTabStatus) {
      this.costTabStatus = true;
    }
  }
  recalculateFreqTab(event) {
    if (event === '2' || this.costTabStatus) {
      this.freqTabStatus = true;
    }
  }
}
export interface DialogData {
  id: any;
}
@Component({
  selector: 'app-dashboard-popup-dialog',
  templateUrl: 'dashboard-dialog.html',
  styleUrls: ['./dashboard-dialog.scss'],
})

// Component loads the confirmation popup
export class DashBoardDialogPopUpComponent {
  constructor(
    public dialogRef: MatDialogRef<DashBoardDialogPopUpComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }
  onNoClick(): void {
    this.dialogRef.close();
  }
}
