import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import {
  INCIDENTTYPEDATA, INCIDENTDATAUPLOAD, INCIDENTTYPE, INCIDENTUPLOAD, COLUMNNAMES,
  FILE_VALIDATION_MESSAGE, SELECT_INCIDENT_VALIDATION_MESSAGE, API_ERROR_MESSAGE,
  FILE_UPLOAD_SUCCESS_MESSAGE, NO_RECORDS
} from '../common/constants/labels';
import { IncidentUploadService } from './incident-upload.service';
import { takeUntil } from 'rxjs/operators';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Subject } from 'rxjs';
import { UploadedIncidentFileDetails } from '../common/models/incident-upload';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Role } from '../common/models/role';
import { Router } from '@angular/router';

export interface DialogData {
  name: '';
}

@Component({
  selector: 'app-incident-upload',
  templateUrl: './incident-upload.component.html',
  styleUrls: ['./incident-upload.component.scss']
})

// Component loads the incident data upload screen
export class IncidentUploadComponent implements OnInit {
  incidentType = INCIDENTTYPEDATA;
  defaultIncidentType = INCIDENTTYPE;
  incidentDataUpload = INCIDENTDATAUPLOAD;
  incidentUpload = INCIDENTUPLOAD;
  showIncidentType = true;
  displayedColumns: any;
  dataSource: Array<UploadedIncidentFileDetails> = [];
  fileToUpload: File = null;
  fileName: string = null;
  incidentTypeValue: string;
  showSuccess = false;
  showValidation = false;
  showFileValidation = false;
  apiError = false;
  progressValue = 0;
  showProgress = false;
  validateSG = '';
  formattedString;
  @ViewChild('files', { static: false }) myInputVariable: ElementRef;
  private rxUnsubscribe: Subject<void> = new Subject<void>();
  fileValidationMsg = FILE_VALIDATION_MESSAGE;
  selectDropdownMsg = SELECT_INCIDENT_VALIDATION_MESSAGE;
  apiErrorMsg = API_ERROR_MESSAGE;
  successMsg = FILE_UPLOAD_SUCCESS_MESSAGE;
  noRecords = NO_RECORDS;
  noRecordsShow = false;
  loading = true;
  role;
  constructor(private incidentservice: IncidentUploadService, public dialog: MatDialog, private router: Router) {
  }
  ngOnInit() {
    this.displayedColumns = COLUMNNAMES;
    this.getIncidentFileDetails();
  }
  // method to display uploaded file details in table
  /* istanbul ignore next */
  getIncidentFileDetails() {
    this.incidentservice.getIncidentFiles().subscribe((response) => {
      if (response.length === 0) {
        this.noRecordsShow = true;
        this.loading = false;
      } else {
        this.noRecordsShow = false;
        const UpdatedResponse = [];
        response.forEach((e) => {
          const uploadedDate = e.uploadedDate;
          const modifiedDate = uploadedDate.split('T');
          const dateOnly = modifiedDate[0];
          let object = {};
          object = {
            fileID: e.fileID,
            fileName: e.fileName,
            incidentType: e.incidentType,
            uploadedDate: dateOnly,
            uploadedBy: e.uploadedBy,
            uploadedStatus: e.uploadedStatus,
            incidents: e.incidents,
            qaMatrixStatus: e.qaMatrixStatus
          };
          if (this.isAuthorized) {
            UpdatedResponse.push(object);
          } else if (!this.isAuthorized && e.qaMatrixStatus === 'Done') {
            UpdatedResponse.push(object);
          }
        });
        UpdatedResponse.sort((a, b) => {
          return <any> (b.fileID) - <any> (a.fileID);
        });

        this.dataSource = UpdatedResponse;
        this.loading = false;
      }
    }, (error) => {
      this.loading = false;
    });
  }

  // method is invoked when user selects value from the list
  selectedIncidentType(value) {
    if (this.defaultIncidentType === INCIDENTTYPE) {
      this.showIncidentType = true;
    }
    if (event) {
      event.preventDefault();
    }
    this.defaultIncidentType = value.name;
    this.incidentTypeValue = value.value;
  }

  // method is invoked to rotate the dropdown icon
  incidentTypeDefaultValue() {
    this.apiError = false;
    this.showValidation = false;
    this.showFileValidation = false;
    this.showIncidentType = !this.showIncidentType;
  }
  // when user will click outside anywhere in the page dropdown will close
  focusOutIncidentType() {
    this.showIncidentType = true;
  }
  // method for uploading file
  /* istanbul ignore next */
  uploadIncidentFile(files: File) {
    this.validateSG = '';
    this.apiError = false;
    this.showValidation = false;
    this.showFileValidation = false;
    if (this.incidentTypeValue === undefined) {
      this.showValidation = true;
    } else {
      this.fileToUpload = files[0];
      this.fileName = files[0].name;
      if ((!this.fileName.endsWith('.xlsx')) && (!this.fileName.endsWith('.xls'))) {
        this.showFileValidation = true;
      } else {
        this.incidentservice.postFile(this.fileToUpload, this.incidentTypeValue).pipe(takeUntil(this.rxUnsubscribe))
          .subscribe((event: HttpEvent<any>) => {
            if (event.type === 0) {
              this.progressValue = 25;
              this.showProgress = true;
            }
            if (event.type === 1) {
              this.progressValue = 50;
              this.showProgress = true;
            }
            if (event.type === 2) {
              this.progressValue = 75;
              this.showProgress = true;
            }
            if (event.type === 3) {
              this.progressValue = 100;
              setTimeout(() => {
                  /* istanbul ignore next */
                this.getIncidentFileDetails();
                this.showProgress = false;
                if (this.validateSG) {
                  this.showSuccess = false;
                } else {
                  this.showSuccess = true;
                }
              }, 1000);
              setTimeout(() => {
                this.showSuccess = false;
                this.defaultIncidentType = INCIDENTTYPE;
                this.incidentTypeValue = undefined;
              }, 6000);
            }
          }, (error) => {
            this.showProgress = false;
            this.showSuccess = false;
            this.validateSG = error.error.message;
            this.formattedString = this.validateSG.split('\n');
            this.openDialogError();
          });
      }
    }
    this.myInputVariable.nativeElement.value = '';
  }
  // method to navigate QAMatrix tab onclick of fileName link
  /* istanbul ignore next */
  loadQAMatrixData(fileId, userId, status) {
    this.showSuccess = false;
    this.router.navigate(['/qamatrix', fileId]);
  }
  // method to navigate Dashboard tab onClick of view dashborad link
  /* istanbul ignore next */
  loadDashboard(fileId) {
    this.showSuccess = false;
    this.router.navigate(['/dashboard', fileId]);
  }
  // method for showing error dialog box
  openDialogError(): void {
    this.dialog.open(IncidentUploadErrorPopupComponent, {
      data: { name: this.formattedString }
    });
  }
  // Authorization
  get isAuthorized() {
    this.role = localStorage.getItem('role');
    if (this.role === Role.qualityManager || this.role === Role.qualityEngineer || this.role === Role.globalAdmin || this.role === Role.ITSupport) {
      return true;
    } else {
      return false;
    }
  }
}

@Component({
  selector: 'app-incident-upload-error-popup',
  templateUrl: 'incident-Upload-Error-Popup.html',
})

// Component loads the incident upload error-popup
/* istanbul ignore next */
export class IncidentUploadErrorPopupComponent {

  constructor(
    public dialogRef: MatDialogRef<IncidentUploadErrorPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
