import { Component, OnInit, Input } from '@angular/core';
import * as c3 from 'c3';

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss']
})
// Created a pie chart component for 4M section in dashboard
export class PieChartComponent implements OnInit {
  @Input() fourmsData: Array<any> = [];
  constructor() { }
  ngOnInit() {
    const foruMsArrayData = Object.keys(this.fourmsData).map(v => new Array(v, this.fourmsData[v]));
    c3.generate({
      bindto: '#chart1',
      data: {
        columns: foruMsArrayData,
        type: 'pie'
      },
      color: {
          // man machine method material
        pattern: ['#578aca', '#cc5755', '#a6c661', '#8a6dac']
      }
    });
  }

}
