import { Component, OnInit, Input, AfterViewInit, ViewChild } from '@angular/core';
import * as c3 from 'c3';
import * as d3 from 'd3';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, SelectControlValueAccessor } from '@angular/forms';
import { reduce } from 'rxjs/operators';
@Component({
  selector: 'app-dpi-pareto-chart',
  templateUrl: './dpi-pareto-chart.component.html',
  styleUrls: ['./dpi-pareto-chart.component.scss']
})
// This component loads pareto chart to show the cummualtive DPI based on DPI score.
export class DpiParetoChartComponent implements OnInit {
  /* istanbul ignore next */
  @Input() tempChildCell: Array<any> = [];
  @Input() cummulativeDPI: [];
  @Input() lossTreeAggregationFull = [];
  @Input() dataInfo: any;
  @Input() type: string;
  constructor() { }
  ngOnInit() {
    /* istanbul ignore next */
    c3.generate({
      bindto: '#chartPareto',
      data: {
        unload: true,
        columns: [
          this.tempChildCell,
          this.cummulativeDPI,
        ],
        type: 'bar',
        types: {
          CumulativeDPI: 'line'
        },
        axes: {
          CumulativeDPI: 'y2'
        },
        color: ((color, d) => {
          return this.setColor(d);
        })
      },
      legend: {
        hide: true
      },
      axis: {
        x: {
          type: 'category',
          categories: this.lossTreeAggregationFull,
          height: 100,
          label: {
            text: this.assignText(),
            position: 'outer-center'
          }
        },
        y: {
          label: { // ADD
            text: 'DPI Score',
            position: 'outer-middle'
          }
        },
        y2: {
          show: true,
          label: { // ADD
            text: 'Cumulative DPI (%)',
            position: 'outer-middle'
          }
        }
      }
    });
  }
  /* set color of bar graphs based on Defect class */
  setColor(value) {
    /* istanbul ignore if */
    if (value.id === 'DPI Score') {
      if (this.type === 'All' || this.type === 'Machines') {
        if (+this.dataInfo[value.index].dpi === +value.value) {
          if (this.dataInfo[value.index].cumulativeDPI <= 50) {
            return '#ff0000';
          } else if (this.dataInfo[value.index].cumulativeDPI > 50 && this.dataInfo[value.index].cumulativeDPI <= 70) {
            return '#ffc000';
          } else if (this.dataInfo[value.index].cumulativeDPI > 70 && this.dataInfo[value.index].cumulativeDPI <= 90) {
            return '#9dc3e6';
          } else {
            return '#a9d18e';
          }
        }
      } else if (this.type === 'AA') {
        return '#ff0000';
      } else if (this.type === 'A') {
        return '#ffc000';
      } else if (this.type === 'B') {
        return '#9dc3e6';
      } else if (this.type === 'C') {
        return '#a9d18e';
      }
    }
  }
  /* assign label text based on the pareto chart type */
  assignText() {
    if (this.type !== 'Machines') {
      return 'Loss Tree Aggregation';
    } else {
      return 'Processes/Machines';
    }
  }
}
