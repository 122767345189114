import { Component, OnInit } from '@angular/core';
import { MENUITEMLIST2 } from '../../constants/labels';

@Component({
  selector: 'app-side-menu-bardashboard',
  templateUrl: './side-menu-bardashboard.component.html',
  styleUrls: ['./side-menu-bardashboard.component.scss']
})

// Component displays side navigation menu across the application
export class SideMenuBarDashboardComponent implements OnInit {
  menuItemlist = MENUITEMLIST2;
  constructor() {
  }

  ngOnInit() {
  }

}
