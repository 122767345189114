import { Component, OnInit, Input, Output, EventEmitter, ViewContainerRef, ViewChild } from '@angular/core';
import {
  PRODUCTION, DATE, PRODUCT, CODE, FORMAT_AGGREGATION,
  AIRSWEB_ID, DESCRIPTION, DEFECT_AGGREGATION, DEFECT_SEVERITY, GRADE, FORMAT, DEFECTMODECOLUMNSNAME
} from '../../common/constants/labels';
import { DEFECT_TABLE_DATA } from './defectTabledata';
import { DefectModeTab } from 'src/app/common/models/qa-matrix-tab';
import { QaMatrixDataModel } from 'src/app/common/models/qa-matrix-save';
import { MatStepper } from '@angular/material/stepper';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Role } from 'src/app/common/models/role';

export interface DefectTableData {
  productionDate: string;
  productCode: number;
  formatAggregation: string;
  airswebId: number;
  description: string;
  defectAggregation: string;
  severityGrade: string;
}
@Component({
  selector: 'app-defect-mode-tab',
  templateUrl: './defect-mode-tab.component.html',
  styleUrls: ['./defect-mode-tab.component.scss']
})

// Component displays the defect mode tab from QA MAtrix creation menu item
export class DefectModeTabComponent implements OnInit {
  date = DATE;
  production = PRODUCTION;
  product = PRODUCT;
  code = CODE;
  format = FORMAT;
  formatAggregation = FORMAT_AGGREGATION;
  airswebId = AIRSWEB_ID;
  description = DESCRIPTION;
  defectAggregation = DEFECT_AGGREGATION;
  defectSeverity = DEFECT_SEVERITY;
  grade = GRADE;
  @Input() dataSourceInput: any;
  @Input() qaMatrixData: any;
  displayedColumns: any;
  dataSource: any;
  ELEMENT_DATA: DefectTableData[] = DEFECT_TABLE_DATA;
  defectData: Array<DefectModeTab> = [];
  saveQaMatrix: Array<QaMatrixDataModel> = [];
  @Output() pushSaveData: EventEmitter<Array<QaMatrixDataModel>> = new EventEmitter();
  @Output() changeImageStatus: EventEmitter<any> = new EventEmitter();
  firstFormGroup: FormGroup;
  role;
  constructor() { }
  @Input() qaForm: FormGroup;
  /* istanbul ignore next */
  ngOnInit() {
    this.displayedColumns = DEFECTMODECOLUMNSNAME;
      /* istanbul ignore if */
    if (this.qaMatrixData.status === 'Done') {
      this.dataSourceInput.sort((a, b) => {
        return (+b.dpi) - (+a.dpi);
      });
    }
    this.dataSourceInput.forEach((e) => {
      const d = new Date(e.dateOccurredOn);
      this.defectData.push({
        productionDate: this.getFormattedDate(d),
        productCode: e.mrdrCode === 0 ? e.materialCode : e.mrdrCode,
        formatAggregation: e.subCategory + ' ' + e.product,
        defectAggregation: e.characterizeLossLevel1 + ' > ' + e.characterizeLossLevel2 + ' > ' + e.characterizeLossLevel3 + ' > ' + e.characterizeLossLevel4,
        description: e.product,
        airswebId: e.qualityIncidentID,
        severityGrade: e.severityGrade,
        format: e.packSize
      });
    });
    this.dataSource = this.defectData;
    this.saveQaMatrix = this.qaMatrixData;
  }
  // method for formatting date
  getFormattedDate(date) {
    if (date) {
      const year = date.getFullYear();
      let month = (1 + date.getMonth()).toString();
      month = month.length > 1 ? month : '0' + month;
      let day = date.getDate().toString();
      day = day.length > 1 ? day : '0' + day;
      return day + '-' + month + '-' + year;
    }
  }
  // method to save changes and navigate to next tab
  saveDefectMode() {
    this.pushSaveData.emit(this.saveQaMatrix);
    this.changeImageStatus.emit('defect/1');
  }
  // updation of format field
    /* istanbul ignore next */
  updateFormat(val, ind) {
    this.qaMatrixData.qaMatrixIncidents[ind].packSize = val.target.value;
    this.saveQaMatrix = this.qaMatrixData;

  }
  // updation of product description field
    /* istanbul ignore next */
  updateDescription(val, indexVal) {
    this.qaMatrixData.qaMatrixIncidents[indexVal].product = val.target.value;
    this.saveQaMatrix = this.qaMatrixData;
  }
  // Authorization
  get isAuthorized() {
    this.role = localStorage.getItem('role');
    if (this.role === Role.qualityManager || this.role === Role.qualityEngineer || this.role === Role.globalAdmin || this.role === Role.ITSupport) {
      return true;
    } else {
      return false;
    }
  }
}
