<div class="mainHead">
    <div class="logo">
        <img class="imgSize" routerLink="/" src="../../../../assets/images/header/Unilever-Logo_White.svg" alt="header">
    </div>
    <div class="headerContent">
        <div class="title"><span class="bold">{{titleHeaderbold}}</span>&nbsp;<span>{{titleHeader}}</span></div>
        <div class="info">
            <div class="flex">
                <div class="dropdown" *ngFor="let info of headerInfo">
                    <img src="../../../../assets/images/header/{{info.img}}.svg" alt="header-info" />
                    <button type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown">
                        {{info.item}}
                    </button>

                </div>
            </div>
            <div class="flex center">
                <div class="login"><img src="../../../../assets/images/header/Login.svg" alt="login" /></div>
                <div class="userDetails">
                    <div>{{LoggedInUserName}} ({{role}})</div>
                    <div>{{LoggedInUserEmail}}</div>
                </div>
                <div class="logout" (click)="logout()"><img src="../../../../assets/images/header/Logout.svg"
                        alt="logout" /></div>
            </div>

        </div>
    </div>
</div>