import {
  HttpEvent,
  HttpEventType,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import { environment } from '../../../environments/environment';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';



@Injectable()
export class MockApiInterceptor implements HttpInterceptor {
  constructor() { }
  public intercept(req: HttpRequest<any>, next: HttpHandler): any {
    let authReq;
    if (!req.url.startsWith('/qmapi')) {
      if (req.url.includes('incidents/files')) {
        authReq = req.clone({
          headers: new HttpHeaders({
            Authorization: 'Bearer ' + localStorage.getItem('Token')
          })
        });
      } else {
        authReq = req.clone({
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('Token')
          })
        });
      }
      return next.handle(authReq);
    } else {
      console.log('API call:', req);
    }
  }
}
