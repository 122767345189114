import { Injectable } from '@angular/core';
import { SETTINGS } from '../common/constants/labels';
import { environment } from '../../environments/environment';
import { ApiService } from '../common/services/api/api.service';
import { Observable } from 'rxjs';
import { StandardInstruction } from '../common/models/standard-instruction';
@Injectable({
  providedIn: 'root'
})
export class StandarInstructionService {
  instructionDataURL = environment.APISETTINGS + SETTINGS;
  constructor(private apisService: ApiService) { }
  getStandardInstructionData(): Observable<StandardInstruction> {
    return this.apisService.get(this.instructionDataURL);
  }
}
