import { Component, OnInit, AfterViewInit, Renderer2, Injectable } from '@angular/core';
import { TITLEHEADER, TITLEHEADER_BOLD } from '../app/common/constants/labels';
import { environment } from './../environments/environment';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError, CanDeactivate } from '@angular/router';
import { LocationStrategy } from '@angular/common';
import { AdalService } from 'adal-angular4';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
/* Application loads with authentication */
export class AppComponent implements OnInit, AfterViewInit {
  title = 'QAMatrix';
  loading = true;
  private adalConfiguration = {
    tenant: environment.tenant,
    clientId: environment.clientId,
    redirectUri: environment.endpoints,
    navigateToLoginRequestUrl: false,
    cacheLocation: 'sessionStorage'
  };
  constructor(private router: Router, private renderer: Renderer2, private adal: AdalService) {
    /* istanbul ignore next */
    this.adal.init(this.adalConfiguration);
    this.adal.handleWindowCallback();
  }
  /* istanbul ignore next */
  ngOnInit() {
    if (this.adal.userInfo) {
      if (!(this.adal.userInfo.userName).endsWith('@unilever.com') || this.adal.userInfo.profile.roles === undefined) {
        this.router.navigate(['/error']);
        this.loading = false;
        setTimeout(() => {
          this.adal.logOut();
          localStorage.clear();
        }, 5000);
      } else {
        this.loading = false;
        localStorage.setItem('factory', this.adal.userInfo.profile.city);
        localStorage.setItem('UserEmailAddress', this.adal.userInfo.userName);
        localStorage.setItem('UserName', this.adal.userInfo.profile.name);
        localStorage.setItem('role', this.adal.userInfo.profile.roles[0]);
        this.adal.acquireToken(this.adal.config.loginResource)
          .subscribe((token: string) => {
            this.adal.userInfo.token = token;
            localStorage.setItem('Token', token);
            if (this.adal.userInfo.authenticated === false) {
              this.adal.userInfo.authenticated = true;
              this.adal.userInfo.error = '';
            }
          }, error => {
            this.adal.userInfo.authenticated = false;
            this.adal.userInfo.error = error;
            this.adal.login();
          });
      }
    }
    this.router.events.subscribe((event) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.loading = true;
          break;
        }
        case event instanceof NavigationEnd: {
          this.loading = false;
          break;
        }
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this.loading = false;
          break;
        }
        default: {
          break;
        }
      }
    });
  }
  /* istanbul ignore next */
  ngAfterViewInit() {
    const loader = this.renderer.selectRootElement('#loader');
    loader.style.display = 'none';
  }
}
