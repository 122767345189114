import { Injectable } from '@angular/core';
import { ApiService } from '../common/services/api/api.service';
import { QaMatrixDataModel } from '../common/models/qa-matrix-save';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { SAVEQAMATRIXDATA } from '../common/constants/labels';


@Injectable({
  providedIn: 'root'
})
export class QaMatrixCreationService {
saveQAMatrixDataURL = environment.APISETTINGS + SAVEQAMATRIXDATA;
  constructor(private service: ApiService) { }

   postQAMatrixAllTabData(data: Array<QaMatrixDataModel>): Observable<any> {
      return this.service.put(this.saveQAMatrixDataURL + data['fileID'], data);
  }
}
