<div class="example-container mat-elevation-z8">
  <table mat-table [dataSource]="relatives">

    <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

    <!-- SINo Column -->
    <ng-container matColumnDef="lossTreeID">
      <mat-header-cell *matHeaderCellDef>Level 0</mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.level0}}</mat-cell>
    </ng-container>
    
    <!-- UploadedBy Column -->
    <ng-container matColumnDef="level1">
      <mat-header-cell *matHeaderCellDef>Level 1</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.level1}} </mat-cell>
    </ng-container>

    <!-- IncidentType Column -->
    <ng-container matColumnDef="level2">
      <mat-header-cell *matHeaderCellDef>Level 2</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.level2}} </mat-cell>
    </ng-container>

    <!-- SINo Column -->
    <ng-container matColumnDef="level3">
      <mat-header-cell *matHeaderCellDef>Level 3</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.level3}} </mat-cell>
    </ng-container>

    <!-- UploadedDate Column -->
    <ng-container matColumnDef="level4">
      <mat-header-cell *matHeaderCellDef>level 4</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.level4}} </mat-cell>
    </ng-container>

    <!-- UploadedBy Column -->
    <ng-container matColumnDef="level5">
      <mat-header-cell *matHeaderCellDef>Level 5</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <textarea id="textareafield" value={{element.level5}} maxlength="100" (focusin)="emitLevel5data()" (blur)="emitData(element, $event)">
          
        </textarea>
      </mat-cell>
    </ng-container>

    <!-- IncidentType Column -->
    <ng-container matColumnDef="severityGrade">
      <mat-header-cell *matHeaderCellDef>Severity Grade</mat-header-cell>     
      <td *matCellDef="let element; let i = index;">
        <button type="button" class="btn btn-primary dropdown-toggle"  *ngIf="element.severityGrade === 'AA' && defaultSeverity === undefined ">
          {{element.severityGrade}}  
        </button>
        <div class="dropdown"  *ngIf="element.severityGrade !== 'AA'">
          <button type="button" class="btn btn-primary dropdown-toggle" (focusout)="focusOutSeverity()"
            data-toggle="dropdown" (click)="severityDefaultValue(i)">
            {{element.severityGrade}}
            <span [ngClass]="showSeverity && rowID == i? 'fa fa-angle-up arrow-position' : 'fa fa-angle-down arrow-position'"></span>
          </button>
          <ul class="dropdown-menu">         
            <li>
              <p class="dropdown-item" href="#" *ngFor="let item of severity" (click)="selectedSeverity(item,i, element)" title="{{item.text}}">
                {{item.text}}</p>
            </li>
          </ul>
        </div>
        <div class="dropdown"  *ngIf="element.severityGrade === 'AA' && defaultSeverity !== undefined">
          <button type="button" class="btn btn-primary dropdown-toggle" (focusout)="focusOutSeverity()"
            data-toggle="dropdown" (click)="severityDefaultValue(i)">
            {{element.severityGrade}}
            <span [ngClass]="showSeverity && rowID == i? 'fa fa-angle-up arrow-position' : 'fa fa-angle-down arrow-position'"></span>
          </button>
          <ul class="dropdown-menu">         
            <li>
              <p class="dropdown-item" href="#" *ngFor="let item of severity" (click)="selectedSeverity(item,i, element)" title="{{item.text}}">
                {{item.text}}</p>
            </li>
          </ul>
        </div>
      </td>
    </ng-container>

    <!-- IncidentType Column -->
    <ng-container matColumnDef="explanationExamples">
      <mat-header-cell *matHeaderCellDef>Explanations/Examples</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <textarea id="textareafield" value={{element.explanationExamples}}  maxlength="500" (focusin)="emitLevel5data()" (blur)="emitDataExp(element, $event)">
      </textarea>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </table>
</div>