import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import {
  PRODUCTION, DATE, FORMAT_AGGREGATION, AIRSWEB_ID,
  DPI, FORMULAE, VSTOTALDPI, CUMMULATIVEDPI, DPIDEFECTCLASS, MS, PDCASTATUS, DPIANDPDCACOLUMNSNAME,
  VALUES, FOURMS, PDCASTATUSVALUES
} from '../../common/constants/labels';
import { DPICalculationsTab } from 'src/app/common/models/qa-matrix-tab';
import { DetectionTabService } from '../detection-point-tab/detection-tab.service';
import { QaMatrixDataModel } from 'src/app/common/models/qa-matrix-save';
import { Role } from 'src/app/common/models/role';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-dpi-cal-pdca',
  templateUrl: './dpi-cal-pdca.component.html',
  styleUrls: ['./dpi-cal-pdca.component.scss']
})
// Component displays the defect mode tab from QA MAtrix creation menu item
export class DpiCalPdcaComponent implements OnInit, OnDestroy {
  date = DATE;
  production = PRODUCTION;
  formatAggregation = FORMAT_AGGREGATION;
  airswebId = AIRSWEB_ID;
  defectPriorityIndex = DPI;
  vstotalDPI = VSTOTALDPI;
  cummulativeDPI = CUMMULATIVEDPI;
  dpiDefectClass = DPIDEFECTCLASS;
  pdca = PDCASTATUS;
  ms = MS;
  formulae = FORMULAE;
  pdcaValues = PDCASTATUSVALUES;
  Ms = FOURMS;
  displayedColumns: any;
  dataSource: any;
  showMs = false;
  rowID;
  showPdca = false;
  restrictEdit = false;
  dpiCalData: Array<DPICalculationsTab> = [];
  dpiCalData1: any = [];
  qaMatrixData: Array<QaMatrixDataModel>;
  sortDpi = [];
  @Input() dataSourceInput: any;
  @Input() allTabData: any;
  @Output() pushAllTabData: EventEmitter<Array<QaMatrixDataModel>> = new EventEmitter();
  @Output() changeImageStatus: EventEmitter<any> = new EventEmitter();
  @Input() costTabStatus: any;
  @Input() freqTabStatus: any;
  role;
  constructor(private deteService: DetectionTabService, private router: Router) {
  }

  ngOnInit() {
    this.displayedColumns = DPIANDPDCACOLUMNSNAME;
    /* istanbul ignore next */
    this.allTabData.qaMatrixIncidents.forEach((e) => {
      const d = new Date(e.dateOccurredOn);
      this.dpiCalData.push({
        productionDate: this.getFormattedDate(d),
        formatAggregation: e.subCategory + ' ' + e.product,
        dpi: e.dpi,
        calc: e.dpIvsTotalDPI,
        cummulativeDPI: e.cumulativeDPI,
        dpiClass: e.defectClass,
        pdca: e.pdcsStatus ? e.pdcsStatus : 'Not Started'
      });
    });
    this.dataSource = this.dpiCalData;
  }
  // method for pdca dropdown
  /* istanbul ignore next */
  selectedPdca(value, indexPdca) {
    this.rowID = indexPdca;
    this.showPdca = false;
    this.dpiCalData[indexPdca].pdca = value;
    this.dataSource[indexPdca].pdca = value;
    this.allTabData.qaMatrixIncidents[indexPdca].pdcsStatus = value;
    if (event) {
      event.preventDefault();
    }
  }
  // method to display the value in pdca dropdown
  pdcaDefaultValue(val) {
    this.rowID = val;
    this.showPdca = !this.showPdca;
  }
  // method to toggle angle arrow (up/down) for pdca dropdown
  focusOutPdca() {
    this.showPdca = false;
  }
  // method for formatting date
  getFormattedDate(date) {
    if (date) {
      const year = date.getFullYear();
      let month = (1 + date.getMonth()).toString();
      month = month.length > 1 ? month : '0' + month;
      let day = date.getDate().toString();
      day = day.length > 1 ? day : '0' + day;
      return day + '-' + month + '-' + year;
    }
  }
  // method to save qa matrix
  /* istanbul ignore next */
  saveQAmatrix() {
    for (let i = 0; i < this.allTabData.qaMatrixIncidents.length; i++) {
      this.allTabData.qaMatrixIncidents[i].pdcsStatus = this.dataSource[i].pdca;
    }
    this.pushAllTabData.emit(this.allTabData);
  }
  // method invokes when user will click prev button
  goBackToDetection() {
    this.changeImageStatus.emit('dpi-cal/2');
  }
  // Authorization
  get isAuthorized() {
     /* istanbul ignore next */
    this.role = localStorage.getItem('role');
    if (this.role === Role.qualityManager || this.role === Role.qualityEngineer || this.role === Role.globalAdmin || this.role === Role.ITSupport) {
      return true;
    } else {
      return false;
    }
  }

  ngOnDestroy() {
     /* istanbul ignore next */
    this.sortDpi = [];
    this.dpiCalData1 = [];
    this.dpiCalData = [];
  }
}
