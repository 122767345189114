import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import {
  CLUSTERSDATA, CLUSTER, FACTORY, CATEGORY, FACTORYCONFIGURATION, CATEGORIESDATA, FACTORIESDATA,
  SUBCATEGORIESDATA, SUB_CATEGORY, LINEID
} from '../common/constants/labels';
import { FactoryInformationService } from './factory-information.service';
import { FactoryConfigurationModel } from '../common/models/factory-configuration';
import * as _ from 'lodash';
import { StandarInstructionService } from '../standard-instruction/standar-instruction.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Role } from '../common/models/role';
export interface DialogData {
  name: string;
}
@Component({
  selector: 'app-factory-information',
  templateUrl: './factory-information.component.html',
  styleUrls: ['./factory-information.component.scss']
})
// Component displays the factory information
export class FactoryInformationComponent implements OnInit {
  categories = CATEGORIESDATA;
  factories = FACTORIESDATA;
  subCategories = SUBCATEGORIESDATA;
  defaultCluster = CLUSTER;
  defaultFactory = FACTORY;
  defaultCategory = CATEGORY;
  defaultSubCategory = SUB_CATEGORY;
  defaultLineName = LINEID;
  showFactory = true;
  showCluster = true;
  showCategory = true;
  showSubCategory = true;
  showLine = true;
  factoryConfiguration = FACTORYCONFIGURATION;
  clusterInfo: any[] = [];
  factoryInfo: any[] = [];
  factoryInformationData;
  lineInfo: any[] = [];
  categoriesInfo: any[] = [];
  categoryFactoryData;
  subCategoriesInfo: any[] = [];
  btnSaveDisabled = true;
  showSuccess = false;
  defaultLineId;
  factoryDetails: Array<FactoryConfigurationModel> = [];
  getCLusteDisabled = true;
  loading = true;
  role;
  constructor(public dialog: MatDialog, private factoryService: FactoryInformationService, private stdInstructionService: StandarInstructionService) { }
  /* istanbul ignore next */
  ngOnInit() {
    this.stdInstructionService.getStandardInstructionData().subscribe((data) => {
      this.loading = true;
      if (data.factoryConfigDetails !== undefined) {
        /* istanbul ignore next */
        this.factoryService.getMenuItems().subscribe((response) => {
          if (response !== undefined) {
            this.loading = false;
            this.factoryInformationData = response;
            /* istanbul ignore next */
            response.forEach((subValue) => {
              this.clusterInfo.push({
                id: subValue.id,
                value: subValue.name
              });
            });
          }
        }, (error) => {
          this.loading = false;
        });
        this.factoryDetails = data.factoryConfigDetails;
        this.factoryDetails.forEach((response) => {
          if (response.createdBy === localStorage.getItem('UserName')) {
            this.getCLusteDisabled = false;
            this.defaultCluster = response.cluster;
            this.defaultFactory = response.factory;
            this.defaultLineName = response.lineName;
            this.defaultCategory = response.category;
            this.defaultSubCategory = response.subCategory;
            this.defaultLineId = response.lineId;
          }
        });
        this.categoriesInfo = [];
        /* istanbul ignore next */
        this.factoryService.getCategory(this.defaultCluster, this.defaultFactory, this.defaultLineId)
          .subscribe((response) => {
            if (response !== undefined) {
              this.categoryFactoryData = response;
              response.Sku.forEach((subValue) => {
                if (subValue.category) {
                  this.categoriesInfo.push({
                    value: subValue.category
                  });
                }
              });
              this.subCategoriesInfo = [];
              this.categoriesInfo = this.categoriesInfo.map(item => item.value)
                .filter((value1, index, self) => self.indexOf(value1) === index);
                /* istanbul ignore next */
              this.categoryFactoryData.Sku.forEach((subValue) => {
                if (subValue.category === this.defaultCategory) {
                  this.subCategoriesInfo.push({
                    value: subValue.subcategory
                  });
                }
              });
              this.subCategoriesInfo = this.subCategoriesInfo.map(item => item.value)
                .filter((value2, index, self) => self.indexOf(value2) === index);
            }
          }, (error) => {
            this.loading = false;
          });
      }
    }, (error) => {
      this.loading = false;
    });
  }
  // methods for Cluster dropdown
  /* istanbul ignore next */
  selectedCluster(value) {
    this.btnSaveDisabled = true;
    if (this.defaultCluster === CLUSTER) {
      this.showCluster = true;
    }
    if (event) {
      event.preventDefault();
    }
    this.defaultCluster = value.value;
    this.defaultFactory = FACTORY;
    this.defaultCategory = CATEGORY;
    this.defaultSubCategory = SUB_CATEGORY;
    this.factoryInfo = [];
    this.defaultLineName = LINEID;
    /* istanbul ignore if */
    if (this.defaultCluster !== CLUSTER) {
      this.factoryInformationData.forEach((subValue) => {
        if (this.defaultCluster === subValue.name) {
          subValue.children.forEach((factoryData) => {
            this.factoryInfo.push({
              id: factoryData.id,
              value: factoryData.name
            });
          });
        }
      });
    }
  }
  // methods to display the value in Cluster dropdown
  /* istanbul ignore next */
  clusterDefaultValue(event) {
    this.showCluster = !this.showCluster;
    this.clusterInfo = [];
    this.factoryInformationData.forEach((subValue) => {
      this.clusterInfo.push({
        id: subValue.id,
        value: subValue.name
      });
    });
    if (this.defaultCluster !== 'Cluster') {
      this.factoryInfo = [];
      this.factoryInformationData.forEach((subValue) => {
        if (event.target.value === subValue.name) {
          subValue.children.forEach((factoryData) => {
            this.factoryInfo.push({
              id: factoryData.id,
              value: factoryData.name
            });
          });
        }
      });
    }
  }
  // method to toggle angle arrow for cluster(up/down)
  focusOutCluster() {
    this.showCluster = true;
  }
  // methods for Factory dropdown
  /* istanbul ignore next */
  selectedFactory(value) {
    this.btnSaveDisabled = true;
    if (this.defaultFactory === FACTORY) {
      this.showFactory = true;
    }
    if (event) {
      event.preventDefault();
    }
    this.defaultFactory = value.value;
    this.defaultCategory = CATEGORY;
    this.defaultSubCategory = SUB_CATEGORY;
    this.defaultLineName = LINEID;
    if (this.defaultFactory !== FACTORY) {
      this.lineInfo = [];
      this.factoryInformationData.forEach((subValue) => {
        if (this.defaultCluster === subValue.name) {
          subValue.children.forEach((factoryData) => {
            if (this.defaultFactory === factoryData.name) {
              factoryData.children.forEach((lineInfoVal) => {
                this.lineInfo.push({
                  id: lineInfoVal.id,
                  value: lineInfoVal.name
                });
              });
            }
          });
        }
      });

    }
  }
  // methods to display the value in Factory dropdown
  factoryDefaultValue() {
    this.showFactory = !this.showFactory;
    if (this.defaultFactory !== FACTORY) {
      this.factoryInfo = [];
      this.factoryInformationData.forEach((subValue) => {
        if (this.defaultCluster === subValue.name) {
          subValue.children.forEach((factoryData) => {
            this.factoryInfo.push({
              id: factoryData.id,
              value: factoryData.name
            });
          });
        }
      });
    }
  }
  // method to toggle angle arrow of factory dropdown(up/down)
  focusOutFactory() {
    this.showFactory = true;
  }
  // methods for Category dropdown
  /* istanbul ignore next */
  selectedCategory(value) {
    this.btnSaveDisabled = true;
    if (this.defaultCategory === CATEGORY) {
      this.showCategory = true;
    }
    if (event) {
      event.preventDefault();
    }
    this.defaultCategory = value;
    this.defaultSubCategory = SUB_CATEGORY;
    this.subCategoriesInfo = [];
    this.categoryFactoryData.Sku.forEach((subValue) => {
      if (subValue.category === this.defaultCategory) {
        this.subCategoriesInfo.push({
          value: subValue.subcategory
        });
      }
    });
    this.subCategoriesInfo = this.subCategoriesInfo.map(item => item.value)
      .filter((value2, index, self) => self.indexOf(value2) === index);
  }
  // methods to display the value in category dropdown
  categoryDefaultValue() {
    this.showCategory = !this.showCategory;

  }
  // method to toggle angle arrow of category dropdown(up/down)
  focusOutCategory() {
    this.showCategory = true;
  }
  // methods for subCategory dropdown
  /* istanbul ignore next */
  selectedSubCategory(value) {
    if (this.defaultSubCategory === SUB_CATEGORY) {
      this.showSubCategory = true;
    }
    if (event) {
      event.preventDefault();
    }
    this.defaultSubCategory = value;
    this.btnSaveDisabled = false;
  }
  // methods to display the value in sub category dropdown
  subCategoryDefaultValue() {
    this.showSubCategory = !this.showSubCategory;
  }
  // method to toggle angle arrow of subcategory dropdown(up/down)
  focusOutSubCategory() {
    this.showSubCategory = true;
  }
  // methods for line dropdown
  /* istanbul ignore next */
  selectedLine(value) {
    if (this.defaultLineName === LINEID) {
      this.showLine = true;
    }
    if (event) {
      event.preventDefault();
    }
    this.defaultLineName = value.value;
    this.defaultLineId = value.id;
    this.defaultCategory = CATEGORY;
    this.defaultSubCategory = SUB_CATEGORY;
    this.categoriesInfo = [];
    this.loading = true;
    this.factoryService.getCategory(this.defaultCluster, this.defaultFactory, value.id)
      .subscribe((response) => {
        if (response !== undefined) {
          this.loading = false;
          this.categoryFactoryData = response;
          response.Sku.forEach((subValue) => {
            if (subValue.category) {
              this.categoriesInfo.push({
                value: subValue.category
              });
            }
          });
          /* istanbul ignore next */
          this.categoriesInfo = this.categoriesInfo.map(item => item.value)
            .filter((value1, index, self) => self.indexOf(value1) === index);
        }
      });
  }
  // methods to display the value in line dropdown
  /* istanbul ignore next */
  lineDefaultValue() {
    this.showLine = !this.showLine;
    this.lineInfo = [];
    this.factoryInformationData.forEach((subValue) => {
      if (this.defaultCluster === subValue.name) {
        subValue.children.forEach((factoryData) => {
          if (this.defaultFactory === factoryData.name) {
            factoryData.children.forEach((lineInfoVal) => {
              this.lineInfo.push({
                id: lineInfoVal.id,
                value: lineInfoVal.name
              });
            });
          }
        });
      }
    });
  }
  // method to toggle angle arrow of line dropdown(up/down)
  focusOutLine() {
    this.showLine = true;
  }
  // method for saving all dropdown changes on click of save button
  /* istanbul ignore next */
  saveFactoryDetails() {
    this.btnSaveDisabled = true;
    const object = new FactoryConfigurationModel();
    object.cluster = this.defaultCluster;
    object.factory = this.defaultFactory;
    object.lineName = this.defaultLineName;
    object.category = this.defaultCategory;
    object.subCategory = this.defaultSubCategory;
    object.createdBy = localStorage.getItem('UserName');
    object.lineId = this.defaultLineId;
    this.factoryService.saveFactoryDetails(object).subscribe((response) => {
      if (response) {
        this.openDialogError();
      }
    });
  }
  // method for showing success dialog box
  openDialogError(): void {
    this.dialog.open(DialogSuccessComponent, {
      data: { name: 'Factory configuration details are saved successfully.' }
    });
  }
  // Authorization
  get isAuthorized() {
    this.role = localStorage.getItem('role');
    if (this.role === Role.qualityManager || this.role === Role.readOnlyUser) {
      return false;
    } else {
      return true;
    }
  }
}
@Component({
  selector: 'app-dialog-success-dialog',
  templateUrl: 'dialog-success-dialog.html',
})

// Component loads the success dialog box
export class DialogSuccessComponent {
  constructor(
    public dialogRef: MatDialogRef<DialogSuccessComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }
}
