import { Component, OnInit } from '@angular/core';
import { TITLEHEADER_BOLD, TITLEHEADER, HEADERINFO } from '../../constants/labels';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

// Component displays the header layout across all the application
export class HeaderComponent implements OnInit {
  titleHeader = TITLEHEADER;
  titleHeaderbold = TITLEHEADER_BOLD;
  headerInfo = HEADERINFO;
  LoggedInUserName;
  LoggedInUserEmail;
  role;
  constructor(public adalSvc: MsAdalAngular6Service) {
  }

  ngOnInit() {
    this.loginInfo();
  }
  // method to set logged in information
  loginInfo() {
    this.LoggedInUserName = localStorage.getItem('UserName');
    this.LoggedInUserEmail = localStorage.getItem('UserEmailAddress');
    this.role = localStorage.getItem('role');
    this.headerInfo[0].item = localStorage.getItem('factory');
  }
  // method for logout
  logout() {
    this.adalSvc.logout();
    localStorage.clear();
  }
}
