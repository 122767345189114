<div class="flexed">
  <button class="btn btn-primary" matStepperNext (click)="saveDefectMode()">Next</button></div>
<div id="personal-details"  class="example-container mat-elevation-z8">
  <table mat-table [dataSource]="dataSource">
    <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

    <!-- productionDate Column -->
    <ng-container matColumnDef="productionDate">
      <th mat-header-cell *matHeaderCellDef>{{production}}<br />{{date}}</th>
      <td mat-cell *matCellDef="let element"> {{element.productionDate}} </td>
    </ng-container>

    <!-- productCode Column -->
    <ng-container matColumnDef="productCode">
      <th mat-header-cell *matHeaderCellDef [ngClass]="customWidthClass">{{product}}<br />{{code}}</th>
      <td mat-cell *matCellDef="let element" [ngClass]="customWidthClass"> {{element.productCode}} </td>
    </ng-container>

    <!-- formatAggregation Column -->
    <ng-container matColumnDef="formatAggregation">
      <th mat-header-cell *matHeaderCellDef>{{formatAggregation}}</th>
      <td mat-cell *matCellDef="let element"> {{element.formatAggregation}} </td>
    </ng-container>

    <ng-container matColumnDef="format">
      <th mat-header-cell *matHeaderCellDef>{{format}}</th>
      <td mat-cell *matCellDef="let element;let i = index" [ngClass]="isAuthorized ? '' : 'readOnly'">
        <input tabindex="-1" matInput value={{element.format}} (focusout)="updateFormat($event,i)">
        <!-- formControlName="firstCtrl" required -->
      </td>
    </ng-container>

    <!-- airswebId Column -->
    <ng-container matColumnDef="airswebId">
      <th mat-header-cell *matHeaderCellDef>{{airswebId}}</th>
      <td mat-cell *matCellDef="let element"> {{element.airswebId}} </td>
    </ng-container>

    <!-- description Column -->
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef>{{product}} {{description}}</th>
      <td mat-cell *matCellDef="let element;let i = index" [ngClass]="isAuthorized ? '' : 'readOnly'">
        <input tabindex="-1" matInput value={{element.description}} (focusout)="updateDescription($event,i)" class="description">
      </td>
    </ng-container>

    <!-- defectAggregation Column -->
    <ng-container matColumnDef="defectAggregation">
      <th mat-header-cell *matHeaderCellDef>{{defectAggregation}}</th>
      <td mat-cell *matCellDef="let element"> {{element.defectAggregation}} </td>
    </ng-container>

    <!-- severityGrade Column -->
    <ng-container matColumnDef="severityGrade">
      <th mat-header-cell *matHeaderCellDef>{{defectSeverity}}<br />{{grade}}</th>
      <td mat-cell *matCellDef="let element"> {{element.severityGrade}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>