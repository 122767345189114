export enum Role {
  ITSupport = 'support',
  lineOperator = 'line-operator',
  globalAdmin = 'GA',
  readOnlyUser = 'read-only-user',
  qualityEngineer = 'quality-engineer',
  qualityManager = 'quality-manager',
  productionAdmin = 'production-admin',
  technicalAdmin = 'technical-admin',
  productionManager = 'production-manager',
  lineSupervisor = 'line-supervisor',
  technicianElectrician = 'technician/electrician'
}
