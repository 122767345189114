<span class="loading" *ngIf="loading">
    <em class="fa fa-spinner fa-spin" style="font-size:40px;color:white"></em>
</span>

<div class="landing-container">
    <div class="logout_landingpage">
        <div>
            <div class="username">{{name}} {{role}}</div>
            <div class="username">{{userName}}</div>
        </div>
        <img src="../../../../assets/images/header/Logout.svg" alt="logout" (click)="logout()" />
    </div>
    <div class="masterLogo row">
        <img src="../../assets/images/landingPage/UNILIVER-LOGO_WHITE.svg" />
    </div>
    <div class="appHeaderName row">{{headerName}}</div>
    <div style="padding-left:95px;padding-right:40px">
        <div class="cardList row col-md-12">
            <div class="alignCard col-md-2" *ngFor="let card of cardItemlist" routerLink="/{{card.id}}">
                <mat-card class="card">
                    <div class="img">
                        <img src="../../assets/images/landingPage/{{card.img}}.svg" />
                    </div>
                    <div class="cardText">{{ card.Item}}</div>
                </mat-card>
            </div>
        </div>
    </div>
</div>