<div class="container">
    <div class="row">
        <div class="col-md-12">
            <div class="error-template">
                <h1>
                    Oops!</h1><br>
                <h3>
                    Please sign in with unilever credentials only and if you are a guest user, you don't have access to QA Matrix application.
                    Hence you will be logged out.
                    <br>
                    <br>Please wait...
                </h3>
            </div>
        </div>
    </div>
</div>
