<span class="loading" *ngIf="loading"><i class="fa fa-spinner fa-spin" style="font-size:40px;color:white"></i></span>
<app-header></app-header>
<app-side-menu-bar></app-side-menu-bar>

<div class="main-content">
  <div class="heading">
    <label class="heading-text">{{factoryConfiguration}}</label>
  </div>
  <button type="button" *ngIf="isAuthorized" class="btn btn-default pull-right" [ngClass]="btnSaveDisabled? 'disabled readOnly' : '' " (click)="saveFactoryDetails()">Save</button>
  <div class="alert alert-success successMsg" *ngIf="showSuccess">
    Factory configuration details are saved successfully.
  </div>
  <div class="alignConfig">
    <div class="row">
      <div class="col-md-3">
        <div class="iconPosition">
          <img src="../../assets/images/Cluster.svg" alt="Cluster" class="clusterIcon">
        </div>
        <div class="dropdown">
          <button type="button" class="btn btn-primary dropdown-toggle" (focusout)="focusOutCluster()"
            data-toggle="dropdown" (click)="clusterDefaultValue($event)"
            [ngClass]="((factoryInformationData !== undefined) || (!getCLusteDisabled)) && (isAuthorized) ? '' : 'disabled readOnly'">
            {{defaultCluster}}
            <span [ngClass]="showCluster ? 'fa fa-angle-down arrow-position' : 'fa fa-angle-up arrow-position'"></span>
          </button>
          <ul class="dropdown-menu">
            <li>
              <p class="dropdown-item" href="#" *ngFor="let item of clusterInfo" (click)="selectedCluster(item)" title="{{item.value}}">
                {{item.value}}</p>
            </li>

          </ul>
        </div>
      </div>

      <div class="col-md-3">
        <div class="iconPosition">
          <img src="../../assets/images/Factory.svg" alt="Factory" class="clusterIcon">
        </div>

        <div class="dropdown">
          <button type="button" class="btn btn-primary dropdown-toggle" (focusout)="focusOutFactory()"
            data-toggle="dropdown" (click)="factoryDefaultValue()"
            [ngClass]="(defaultCluster !== 'Cluster') && (isAuthorized) ? '' : 'disabled readOnly'">
            {{defaultFactory}}
            <span [ngClass]="showFactory ? 'fa fa-angle-down arrow-position' : 'fa fa-angle-up arrow-position'"></span>
          </button>
          <ul class="dropdown-menu">

            <li>
              <p class="dropdown-item" href="#" *ngFor="let item of factoryInfo" (click)="selectedFactory(item)" title="{{item.value}}">
                {{item.value}}</p>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-md-3">
        <div class="iconPosition">
          <img src="../../assets/images/manufacturing.svg" alt="Line Id" class="clusterIcon">
        </div>
        <div class="dropdown">
          <button type="button" class="btn btn-primary dropdown-toggle" (focusout)="focusOutLine()"
            data-toggle="dropdown" (click)="lineDefaultValue()"
            [ngClass]="(defaultFactory !== 'Factory') && (isAuthorized) ? '' : 'disabled readOnly'">
            {{defaultLineName}}
            <span [ngClass]="showLine? 'fa fa-angle-down arrow-position' : 'fa fa-angle-up arrow-position'"></span>
          </button>
          <ul class="dropdown-menu">
            <li>
              <p class="dropdown-item" title="{{item.value}}" href="#" *ngFor="let item of lineInfo" (click)="selectedLine(item)">
                {{item.value}}</p>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-md-3">
        <div class="iconPosition">
          <img src="../../assets/images/Category.svg" alt="Category" class="clusterIcon">
        </div>
        <div class="dropdown">
          <button type="button" class="btn btn-primary dropdown-toggle" (focusout)="focusOutCategory()"
            data-toggle="dropdown" (click)="categoryDefaultValue()"
            [ngClass]="(defaultLineName !== 'Line') && (isAuthorized) ? '' : 'disabled readOnly'">
            {{defaultCategory}}
            <span [ngClass]="showCategory? 'fa fa-angle-down arrow-position' : 'fa fa-angle-up arrow-position'"></span>
          </button>
          <ul class="dropdown-menu">
            <li>
              <p class="dropdown-item" title="{{item}}"href="#" *ngFor="let item of categoriesInfo" (click)="selectedCategory(item)">
                {{item}}</p>
            </li>
          </ul>
        </div>
      </div>


    </div> <br>
    <div class="row">
      <div class="col-md-3">
        <div class="iconPosition">
          <img src="../../assets/images/subCategory.svg" alt="Category" class="clusterIcon">
        </div>
        <div class="dropdown">
          <button type="button" class="btn btn-primary dropdown-toggle" (focusout)="focusOutSubCategory()"
            data-toggle="dropdown" (click)="subCategoryDefaultValue()"
            [ngClass]="(defaultCategory !== 'Category') && (isAuthorized) ? '' : 'disabled readOnly'">
            {{defaultSubCategory}}
            <span
              [ngClass]="showSubCategory? 'fa fa-angle-down arrow-position' : 'fa fa-angle-up arrow-position'"></span>
          </button>
          <ul class="dropdown-menu dd-subcategory">
            <li>
              <p class="dropdown-item" title="{{item}} "href="#" *ngFor="let item of subCategoriesInfo" (click)="selectedSubCategory(item)">
                {{item}}</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>