import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SETTINGS, LOSSTREE, LOSSTREEEDIT_GETFILES } from '../common/constants/labels';
import { environment } from '../../environments/environment';
import { ApiService } from '../common/services/api/api.service';
import { StandardInstruction } from '../common/models/standard-instruction';
import { LossTreeParent, LossTree } from '../common/models/loss-tree';
import { delay } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LossTreeService {
  severityGradeURL = environment.APISETTINGS + SETTINGS;
  lossTreeURL = environment.APISETTINGS + LOSSTREE + localStorage.getItem('factory') + '/losstree';
  EditLossTreeURL = environment.APISETTINGS + LOSSTREEEDIT_GETFILES;
  constructor(private service: ApiService, private router: Router) {
  }

  public getSeverityGrade(): Observable<StandardInstruction> {
    return this.service.get(this.severityGradeURL);
  }
  public getLossTreeData(): Observable<any> {
    return this.service.get(this.lossTreeURL);
  }
  public editLossTreeData(lossTreeObj: LossTree[]): Observable<any> {
    return this.service.post(this.lossTreeURL, lossTreeObj);
  }

  public reloadLossTree() {
    this.router.navigateByUrl('/configrator', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/losstree']);
    });
  }
}
