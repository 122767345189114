import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { HeaderComponent } from './common/components/header/header.component';
import { SideMenuBarComponent } from './common/components/side-menu-bar/side-menu-bar.component';
import { FactoryInformationComponent } from './factory-information/factory-information.component';
import { IncidentUploadComponent } from './incident-upload/incident-upload.component';
import { QaMatrixCreationComponent } from './qa-matrix-creation/qa-matrix-creation.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { StandardInstructionComponent } from './standard-instruction/standard-instruction.component';
import { DummyComponent } from './common/components/dummy/dummy.component';
import { AuthenticationGuard } from 'microsoft-adal-angular6';
import { DpiParetoChartComponent } from './dashboard/dpi-pareto-chart/dpi-pareto-chart.component';
import { PieChartComponent } from './dashboard/pie-chart/pie-chart/pie-chart.component';
import { ErrorPageComponent } from './common/components/error-page/error-page.component';
import { LossTreeComponent } from './loss-tree/loss-tree.component';

// created routes for lazy loading implementation
const routes: Routes = [
  { path: '', component: LandingPageComponent, canActivate: [AuthenticationGuard]},
  { path: 'error', component: ErrorPageComponent, canActivate: [AuthenticationGuard]},
  { path: 'factoryconfig', component: FactoryInformationComponent, canActivate: [AuthenticationGuard]},
  { path: 'files', component: IncidentUploadComponent, canActivate: [AuthenticationGuard]},
  { path: 'qamatrix/:fileId', component: QaMatrixCreationComponent, canActivate: [AuthenticationGuard]},
  { path: 'instruction', component: StandardInstructionComponent, canActivate: [AuthenticationGuard]},
  { path: 'dashboard/:fileId', component  : DashboardComponent, canActivate: [AuthenticationGuard]},
  { path: 'configrator', component: DummyComponent, canActivate: [AuthenticationGuard]},
  { path: 'losstree', component: LossTreeComponent, canActivate: [AuthenticationGuard]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
