<span class="loading" *ngIf="loading"><i class="fa fa-spinner fa-spin" style="font-size:40px;color:white"></i></span>

<app-header></app-header>
<app-side-menu-bar></app-side-menu-bar>
<div class="main-content fixed-height">
  <div class="heading">
    <label class="heading-text">{{standardinstruction}}</label>
  </div>
  <div class="dashboardbg">
    <div class="std-section">
      <div class="std-heading">
        <p class="stdheading">{{stdPrioritization}} </p>
        <br>
        <div class="row heading-row">
          <div class="col-sm-2 col-width">
            <span class="text-color">PI</span><br><span class="names">{{priorityIndex}}</span>
          </div>
          <div class="col-sm-1 spansec">
            {{equalto}}
          </div>
          <div class="col-sm-2 col-width">
            <span class="text-color">S</span><br><span class="names">{{severity}}</span>
          </div>
          <div class="col-sm-1 spansec">
            {{multiply}}
          </div>
          <div class="col-sm-2 col-width">
            <span class="text-color">F</span><br><span class="names">{{freq}}</span>
          </div>
          <div class="col-sm-1 spansec">
            {{multiply}}
          </div>
          <div class="col-sm-2 col-width">
            <span class="text-color">C</span><br><span class="names">{{cost}}</span>
          </div>
          <div class="col-sm-1 spansec">
            {{multiply}}
          </div>
          <div class="col-sm-2 col-widthdpi">
            <span class="text-color">&#931;Di</span><br><span class="names">{{dIndex}}</span>
          </div>
        </div>

      </div>

      <div class="tables row">
        <div class="col-sm-3 col-xs-3 rm-padding adjust-width">
          <p class="table-heading">{{severity}}</p>
          <div class="example-container mat-elevation-z8">
            <table mat-table [dataSource]="dataSourceSeverity">

              <!--- Note that these columns can be defined in any order.
                                  The actual rendered columns are set as a property on the row definition" -->

              <!-- Severity grade Column -->
              <ng-container matColumnDef="Severity Grade">
                <th mat-header-cell *matHeaderCellDef class="first-col">Severity Grade</th>
                <td mat-cell *matCellDef="let element"> {{element.severityGrade}} </td>
              </ng-container>

              <!-- Description Column -->
              <ng-container matColumnDef="Short Description">
                <th mat-header-cell *matHeaderCellDef class="second-col">Short Description</th>
                <td mat-cell *matCellDef="let element"> {{element.shortDescription}} </td>
              </ng-container>

              <!-- Criteria Column -->
              <ng-container matColumnDef="Criteria">
                <th mat-header-cell *matHeaderCellDef class="third-col">Criteria</th>
                <td mat-cell *matCellDef="let element"> {{element.severityCriteria}} </td>
              </ng-container>

              <!-- Index Column -->
              <ng-container matColumnDef="Index">
                <th mat-header-cell *matHeaderCellDef class="fourth-col">Index</th>
                <td mat-cell *matCellDef="let element"> {{element.severityIndex}} </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </div>
        </div>
        <div class="col-sm-3 col-xs-3 rm-padding space">
          <p class="table-heading">{{frequency}}</p>
          <div class="example-container mat-elevation-z8">
            <table mat-table [dataSource]="dataSourceFreq">

              <!--- Note that these columns can be defined in any order.
                                      The actual rendered columns are set as a property on the row definition" -->

              <!-- Criteria Column -->
              <ng-container matColumnDef="Criteria">
                <th mat-header-cell *matHeaderCellDef class="cost-col1">Criteria</th>
                <td mat-cell *matCellDef="let element"> {{element.frequencyCriteria}} </td>
              </ng-container>

              <!-- From Column -->
              <ng-container matColumnDef="From (%)">
                <th mat-header-cell *matHeaderCellDef class="cost-col4">From (%)</th>
                <td mat-cell *matCellDef="let element"> {{element.from}} </td>
              </ng-container>

              <!-- To Column -->
              <ng-container matColumnDef="To (%)">
                <th mat-header-cell *matHeaderCellDef class="cost-col3">To (%)</th>
                <td mat-cell *matCellDef="let element"> {{element.to}} </td>
              </ng-container>

              <!-- Index Column -->
              <ng-container matColumnDef="Index">
                <th mat-header-cell *matHeaderCellDef class="cost-col4">Index</th>
                <td mat-cell *matCellDef="let element"> {{element.frequencyIndex}} </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="costDisplayedColumns;sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: costDisplayedColumns;"></tr>
            </table>
          </div>
        </div>
        <div class="col-sm-3 col-xs-3 rm-padding space">
          <p class="table-heading">{{cost}}</p>
          <div class="example-container mat-elevation-z8">
            <table mat-table [dataSource]="dataSourceCost">

              <!--- Note that these columns can be defined in any order.
                                  The actual rendered columns are set as a property on the row definition" -->

              <!-- Criteria Column -->
              <ng-container matColumnDef="Criteria">
                <th mat-header-cell *matHeaderCellDef class="cost-col1">Criteria</th>
                <td mat-cell *matCellDef="let element"> {{element.costCriteria}} </td>
              </ng-container>

              <!-- From Column -->
              <ng-container matColumnDef="From (%)">
                <th mat-header-cell *matHeaderCellDef class="cost-col4">From (%)</th>
                <td mat-cell *matCellDef="let element"> {{element.from}} </td>
              </ng-container>

              <!-- To Column -->
              <ng-container matColumnDef="To (%)">
                <th mat-header-cell *matHeaderCellDef class="cost-col3">To (%)</th>
                <td mat-cell *matCellDef="let element"> {{element.to}} </td>
              </ng-container>

              <!-- Index Column -->
              <ng-container matColumnDef="Index">
                <th mat-header-cell *matHeaderCellDef class="cost-col4">Index</th>
                <td mat-cell *matCellDef="let element"> {{element.costIndex}} </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="costDisplayedColumns;sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: costDisplayedColumns;"></tr>
            </table>
          </div>
        </div>
        <div class="col-sm-3 col-xs-3 rm-padding adjust1-width">
          <p class="table-heading dIndex">{{dIndex}}</p>
          <div class="example-container mat-elevation-z8">
            <table mat-table [dataSource]="dataSourceDetIndex">

              <!--- Note that these columns can be defined in any order.
                                      The actual rendered columns are set as a property on the row definition" -->

              <!-- Criteria Column -->
              <ng-container matColumnDef="Criteria">
                <th mat-header-cell *matHeaderCellDef class="det-col1">Criteria</th>
                <td mat-cell *matCellDef="let element"> {{element.detectabilityCriteria}} </td>
              </ng-container>


              <!-- Index Column -->
              <ng-container matColumnDef="Index">
                <th mat-header-cell *matHeaderCellDef class="cost-col4">Index</th>
                <td mat-cell *matCellDef="let element"> {{element.detectabilityIndex}} </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="detectabilityDisplayedColumns;sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: detectabilityDisplayedColumns;"></tr>
            </table>
          </div>
        </div>
      </div>


    </div>

    <div class="row classification">
      <div class="col-sm-4 rwd-heading">
        <p class="table-heading1">{{classificationDPI}}</p>

        <table class="classify">
          <tr *ngFor="let item of dataSourceClassification">
            <td>{{item.defectClassification}}</td>
          </tr>
        </table>

      </div>
      <div class="col-sm-4 rwd-padding">
        <p class="table-heading1">{{internalDetPoints}}</p>
        <p class="defect-type">{{defectType}}</p>
        <table class="internal">
          <tr *ngFor="let item of dataSourceInt">
            <td>{{item.defectTypes}}</td>
          </tr>
        </table>
      </div>
      <div class="col-sm-4">
        <p class="table-heading1 extDet">{{externalDetPoints}}</p>
        <p class="defect-type">{{defectType}}</p>
        <table class="internal">
          <tr *ngFor="let item of dataSourceExt">
            <td class="ext-col">{{item.defectTypes}}</td>
            <td>{{item.description}}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>