<div class="flexed">
  <button class="btn primary" matStepperPrevious (click)="goBackToCost()">Prev</button>
  <button style="margin-left:10px" class="btn primary"  [ngClass]="enableToNavigate ? '': 'disable'" matStepperNext (click)="saveDetection()">Next</button></div>
<div class="example-container mat-elevation-z8">
  <table mat-table [dataSource]="dataSource">

    <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

    <!-- productionDate Column -->
    <ng-container matColumnDef="productionDate">
      <th mat-header-cell *matHeaderCellDef>{{production}}<br />{{date}}</th>
      <td mat-cell *matCellDef="let element"> {{element.productionDate}} </td>
    </ng-container>

    <!-- formatAggregation Column -->
    <ng-container matColumnDef="formatAggregation">
      <th mat-header-cell *matHeaderCellDef class="det-format">{{formatAggregation}}</th>
      <td mat-cell *matCellDef="let element"> {{element.formatAggregation}} </td>
    </ng-container>

    <!-- Occurance Column -->
    <ng-container matColumnDef="occurance">
      <th mat-header-cell *matHeaderCellDef class="padding-cols">{{occurance}}</th>
      <td mat-cell *matCellDef="let element; let i = index" [ngClass]="isAuthorized ? '' : 'readOnly'">
        <div class="dropdown">
          <button type="button"  class="btn btn-primary dropdown-toggle" (focusout)="focusOutOccurance()"
            data-toggle="dropdown" (click)="occuranceDefaultValue(i)">
            {{element.occurance}}
            <span
              [ngClass]="showOccurance && rowID == i ? 'fa fa-angle-up arrow-position':'fa fa-angle-down arrow-position'"></span>

          </button>
          <ul class="dropdown-menu">
            <li>
            <p class="dropdown-item" title="{{item}}" href="#" *ngFor="let item of machineNames" (click)="selectedOccurance(item,i)">
              {{item}}</p></li>
            </ul>
        </div>
      </td>
    </ng-container>

    <!-- 4Ms Column -->
    <ng-container matColumnDef="ms">
      <th mat-header-cell *matHeaderCellDef class="padding-cols">{{ms}}</th>
      <td mat-cell *matCellDef="let element; let i = index;" [ngClass]="isAuthorized ? '' : 'readOnly'">
        <div class="dropdown">
          <button type="button"  class="btn btn-primary dropdown-toggle" (focusout)="focusOutMs()" data-toggle="dropdown"
            (click)="msDefaultValue(i)">
            {{element.ms}}
            <span
              [ngClass]="showMs && rowID == i ? 'fa fa-angle-up arrow-position res1' : 'fa fa-angle-down arrow-position res1'"></span>

          </button>
          <ul class="dropdown-menu res2">
            <li>
            <p class="dropdown-item" title="{{item}}" href="#" *ngFor="let item of Ms" (click)="selectedMs(item,i)">
              {{item}}</p></li>
            </ul>
        </div>
      </td>
    </ng-container>

    <!-- Critical Component -->
    <ng-container matColumnDef="criticalComponent">
      <th mat-header-cell *matHeaderCellDef>Critical Quality Component</th>
      <td mat-cell *matCellDef="let element;let i = index" [ngClass]="isAuthorized ? '' : 'readOnly'" class="res3">
        <!-- {{element.totalProdAmount}} -->
        <input maxlength="50" class="res4" [disabled]="element.ms == 'Machine'? false : true" value={{element.criticalComponent}} (focusout)="updateCriticalComponent(element,$event,i)">
      </td>
    </ng-container>

    <!-- Detection Type Column -->
    <ng-container matColumnDef="detectionType">
      <th mat-header-cell *matHeaderCellDef class="padding-cols">Detection Type</th>
      <td mat-cell *matCellDef="let element; let i = index;" [ngClass]="isAuthorized ? '' : 'readOnly'">
        <div class="dropdown">
          <button type="button"  class="btn btn-primary dropdown-toggle" data-toggle="dropdown" (focusout)="focusOutDetectionType()"
            (click)="detectionTypeDefaultType(i)">
            {{element.detectionType}}
            <span
              [ngClass]="showDetectype && rowID == i ? 'fa fa-angle-up arrow-position' : 'fa fa-angle-down arrow-position'"></span>

          </button>
          <ul class="dropdown-menu">
            <li>
            <p class="dropdown-item" title="{{item}}" href="#" *ngFor="let item of detectionTypes" (click)="selectedDetectionType(item,i)">
              {{item}}</p></li>
            </ul>
        </div>
      </td>
    </ng-container>

    <!-- Detection point Column -->
    <ng-container matColumnDef="detectionpoint">
      <th mat-header-cell *matHeaderCellDef class="padding-cols">{{detectionpoint}}</th>
      <td mat-cell *matCellDef="let element; let i = index;" [ngClass]="isAuthorized ? '' : 'readOnly'">
        <div class="dropdown">
          <button type="button"  class="btn btn-primary dropdown-toggle" (focusout)="focusOutDetectionPoint()"
            data-toggle="dropdown" (click)="detectionPointDefaultValue(i)">
            {{element.detectionPoint}}
            <span
              [ngClass]="showDetectionPoint && rowID == i? 'fa fa-angle-up arrow-position' : 'fa fa-angle-down arrow-position'"></span>

          </button>
          <ul class="dropdown-menu"><li>
            <p class="dropdown-item" title="{{item}}"href="#" *ngFor="let item of machineNames" (click)="selectedDetectionPoint(item, i,element)">
              {{item}}</p></li>
            </ul>
        </div>
      </td>
    </ng-container>


    <ng-container matColumnDef="detectability">
      <th mat-header-cell *matHeaderCellDef>{{detectability}}</th>
      <td mat-cell *matCellDef="let element"> {{element.detectability}} </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>