<div class="flexed">
  <button class="btn btn-primary" matStepperPrevious (click)="goBackToDefect()">Prev</button>
  <button style="margin-left:10px" class="btn btn-primary" [ngClass]="enableToNavigate ? '': 'disable'" matStepperNext
    (click)="saveFrequency()">Next</button>
</div>
<div class="example-container mat-elevation-z8" id="contact-details" [formGroup]="qaForm">
  <table mat-table [dataSource]="dataSource">

    <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

    <!-- productionDate Column -->
    <ng-container matColumnDef="productionDate">
      <th mat-header-cell *matHeaderCellDef>{{production}}<br />{{date}}</th>
      <td mat-cell *matCellDef="let element"> {{element.productionDate}} </td>
    </ng-container>

    <!-- formatAggregation Column -->
    <ng-container matColumnDef="formatAggregation">
      <th mat-header-cell *matHeaderCellDef>{{formatAggregation}}</th>
      <td mat-cell *matCellDef="let element"> {{element.formatAggregation}} </td>
    </ng-container>

    <!-- airswebId Column -->
    <!-- <ng-container matColumnDef="airswebId">
          <th mat-header-cell *matHeaderCellDef>{{airswebId}}</th>
          <td mat-cell *matCellDef="let element"> {{element.airswebId}} </td>
        </ng-container> -->

    <!-- blockedproduct Column -->
    <ng-container matColumnDef="blockedproduct">
      <th mat-header-cell *matHeaderCellDef>{{blockedproduct}}</th>
      <td mat-cell *matCellDef="let element"> {{element.noOfBlockedProduct}} </td>
    </ng-container>

    <!-- ProductionAmount Column -->
    <ng-container matColumnDef="ProductionAmount" formGroupName="frequencyDetails">
      <th mat-header-cell *matHeaderCellDef>{{ProdAmount}}<br />{{avgBatch}}</th>
      <td mat-cell *matCellDef="let element;let i = index" [ngClass]="isAuthorized ? '' : 'readOnly'">
        <!-- {{element.totalProdAmount}} -->
        <input type="number" tabindex="-1" formControlName="avgBatchValue" required
          onkeydown="return event.keyCode !== 69" matInput value={{element.totalProdAmount}}
          onkeypress='return event.charCode >= 48 && event.charCode <= 57'
          (focusout)="updateCalculationFields(element,$event,i)" maxlength="10"
          oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">
        <p *ngIf="+element.totalProdAmount < +element.noOfBlockedProduct && (+element.totalProdAmount)"  class="validationMsg">value should be greater than <br> or equal to # of blocked product</p>
      </td>
    </ng-container>
    <!-- UoM Column -->
    <ng-container matColumnDef="UoM">
      <th mat-header-cell *matHeaderCellDef>{{UoM}}</th>
      <td mat-cell *matCellDef="let element"> {{element.Uom}} </td>
    </ng-container>

    <!-- affectedProduct Column -->
    <ng-container matColumnDef="affectedProduct">
      <th mat-header-cell *matHeaderCellDef>{{affectedProduct}}</th>
      <td mat-cell *matCellDef="let element">{{element.percOfAffectedProd}}</td>
    </ng-container>

    <!-- frequencyScore Column -->
    <ng-container matColumnDef="frequencyScore">
      <th mat-header-cell *matHeaderCellDef>{{freqScore}}</th>
      <td mat-cell *matCellDef="let element"> {{element.freqScore}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>