import { Component, OnInit, Input } from '@angular/core';
import * as c3 from 'c3';

@Component({
  selector: 'app-pie-chart-pdca',
  templateUrl: './pie-chart-pdca.component.html',
  styleUrls: ['./pie-chart-pdca.component.scss']
})

// Created a pie chart component for PDCA section in dashboard
export class PieChartPdcaComponent implements OnInit {
  @Input() pdcaData: Array<any> = [];
  constructor() { }

  ngOnInit() {
    const pdcaArrayData = Object.keys(this.pdcaData).map(v => new Array(v, this.pdcaData[v]));
    c3.generate({
      data: {
        columns: pdcaArrayData,
        type: 'pie'
      },
      color: {
        // notstarted plan do check act
      pattern: ['#ffd9a4', '#ffff00', '#ffbd06', '#ff0000', '#00af52']
    }
    });
  }

}
