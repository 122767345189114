export const FREQUENCY_TABLE_DATA = [
    {
        productionDate: '17-09-2019',
        formatAggregation: 'DEO Roll-ons Vaseline 50ml',
        blockedproduct: 3384,
        ProductionAmount: 3384,
        UoM: 'pcs',
        affectedProduct: 100,
        frequencyScore: 5
    },
    {
        productionDate: '22-10-2019',
        formatAggregation: 'DEO Spray Rexona 200ml',
        blockedproduct: 6426,
        ProductionAmount: 6426,
        UoM: 'zun',
        affectedProduct: 100,
        frequencyScore: 5
    }
    // {
    //     productionDate: '29-07-2019',
    //     formatAggregation: 'HPC DEO Spray Lynx 150ml',
    //     airswebId: 368988,
    //     blockedproduct: 2304,
    //     ProductionAmount: 160320,
    //     UoM: 'pcs',
    //     affectedProduct: 1.44,
    //     frequencyScore: 4
    // },
    // {
    //     productionDate: '08-10-2019',
    //     formatAggregation: 'DEO Spray Dove 150ml',
    //     airswebId: 372425,
    //     blockedproduct: 28512,
    //     ProductionAmount: 76500,
    //     UoM: 'pcs',
    //     affectedProduct: 37.27,
    //     frequencyScore: 5
    // },
    // {
    //     productionDate: '21-10-2019',
    //     formatAggregation: 'DEO Spray Axe 150ml',
    //     airswebId: 373083,
    //     blockedproduct: 4212,
    //     ProductionAmount: 125970,
    //     UoM: 'zun   ',
    //     affectedProduct: 3.34,
    //     frequencyScore: 4
    // },
    // {
    //     productionDate: '05-08-2019',
    //     formatAggregation: 'DEO Spray Dove 200ml',
    //     airswebId: 367178,
    //     blockedproduct: 11520,
    //     ProductionAmount: 181968,
    //     UoM: 'pcs',
    //     affectedProduct: 6.33,
    //     frequencyScore: 4
    // },
    // {
    //     productionDate: '08-10-2019',
    //     formatAggregation: 'DEO Spray Dove 150ml',
    //     airswebId: 372425,
    //     blockedproduct: 28512,
    //     ProductionAmount: 76500,
    //     UoM: 'pcs',
    //     affectedProduct: 37.27,
    //     frequencyScore: 5
    // },
    // {
    //     productionDate: '21-10-2019',
    //     formatAggregation: 'DEO Spray Axe 150ml',
    //     airswebId: 373083,
    //     blockedproduct: 4212,
    //     ProductionAmount: 125970,
    //     UoM: 'zun   ',
    //     affectedProduct: 3.34,
    //     frequencyScore: 4
    // }
];
