// Landing Page
export const LANDINGPAGE_HEADER = 'QA MATRIX';
export const CARDITEMLIST = [
  { Item: 'STANDARD INSTRUCTION', id: 'instruction', img: 'STANDARD-INSTRUCTION' },
  { Item: 'FACTORY CONFIGURATION', id: 'factoryconfig', img: 'FACTORY-CONFIGRATION' },
  { Item: 'CONFIGURATOR', id: 'losstree', img: 'CONFIGRATOR' },
  { Item: 'INCIDENT DATA UPLOAD', id: 'files', img: 'INCIDENT-DATA-UPLOAD' },
  // { Item: 'QA MATRIX CREATION', id: 'QAmatrix', img: 'QA-MATRIX-CREATION' },
  // { Item: 'DASHBOARD', id: '', img: 'DASHBOARD' }
];

// Header
export const TITLEHEADER_BOLD = 'QA';
export const TITLEHEADER = 'MATRIX';
export const HEADERINFO = [
  { item: 'BANGALORE', img: 'Factory_Small_Gray' },
  { item: 'ENGLISH', img: 'Language_Gray' },
  { item: 'EURO', img: 'Curency_Gray' }
];

// Side-menu-bar
export const MENUITEMLIST = [
  { Item: 'STANDARD INSTRUCTION', id: 'instruction', img: 'Standard-Instructions_Gray' },
  { Item: 'FACTORY CONFIGURATION', id: 'factoryconfig', img: 'FactoryConfigration_Gray' },
  { Item: 'CONFIGURATOR', id: 'losstree', img: 'Configurator_Gray' },
  { Item: 'INCIDENT DATA UPLOAD', id: 'files', img: 'Incident-Data-Upload_Gray' },
  // // { Item: 'QA MATRIX CREATION', id: 'QAmatrix', img: 'QA-Matrix-Creation_Gray' },
  // { Item: 'DASHBOARD', id: '', img: 'Dashboard_Gray' },
  { Item: '', id: '1', img: 'noImg' }
];
export const MENUITEMLIST1 = [
  { Item: 'STANDARD INSTRUCTION', id: 'instruction', img: 'Standard-Instructions_Gray' },
  { Item: 'FACTORY CONFIGURATION', id: 'factoryconfig', img: 'FactoryConfigration_Gray' },
  { Item: 'CONFIGURATOR', id: 'losstree', img: 'Configurator_Gray' },
  { Item: 'INCIDENT DATA UPLOAD', id: 'files', img: 'Incident-Data-Upload_Gray' },
  { Item: 'QA MATRIX CREATION', id: 'qamatrix', img: 'QA-Matrix-Creation_Gray' },
 // { Item: 'DASHBOARD', id: '', img: 'Dashboard_Gray' },
  { Item: '', id: '1', img: 'noImg' }
];
export const MENUITEMLIST2 = [
  { Item: 'STANDARD INSTRUCTION', id: 'instruction', img: 'Standard-Instructions_Gray' },
  { Item: 'FACTORY CONFIGURATION', id: 'factoryconfig', img: 'FactoryConfigration_Gray' },
  { Item: 'CONFIGURATOR', id: 'losstree', img: 'Configurator_Gray' },
  { Item: 'INCIDENT DATA UPLOAD', id: 'files', img: 'Incident-Data-Upload_Gray' },
  // { Item: 'QA MATRIX CREATION', id: 'QAmatrix', img: 'QA-Matrix-Creation_Gray' },
  { Item: 'DASHBOARD', id: '', img: 'Dashboard_Gray' },
  { Item: '', id: '1', img: 'noImg' }
];

// factory Configurator page
export const CLUSTERSDATA: any[] = [
  { name: 'Europe', value: 'europe' },
  { name: 'Nametrub', value: 'trub' },
  { name: 'LATAM', value: 'latam' },
  { name: 'S Asia', value: 's asia' },
  { name: 'A Asia', value: 'a asia' },
  { name: 'SEAA', value: 'seaa' }
];
export const CLUSTER = 'Cluster';
export const FACTORY = 'Factory';
export const CATEGORY = 'Category';
export const SUB_CATEGORY = 'Sub Category';
export const LINEID = 'Line';
export const FACTORYCONFIGURATION = 'FACTORY CONFIGURATION';
export const CATEGORIESDATA: any[] = [
  { name: 'Food & Refreshments', value: 'food' },
  { name: 'Personal Care', value: 'pc' },
  { name: 'Home Care', value: 'hc' }
];
export const FACTORIESDATA: any[] = [
  { name: 'Leeds', value: 'leeds' },
  { name: 'Mangalore', value: 'mangalore' },
  { name: 'Bangalore', value: 'bangalore' }
];
export const SUBCATEGORIESDATA: any[] = [
  { name: 'Roll-ons', value: 'Roll-ons' },
  { name: 'Spray', value: 'Spray' }
];

// Incident Data Upload page
export const INCIDENTTYPEDATA: any[] = [
  { name: 'ABC Incident', value: 'ABC' },
  { name: 'D Incident', value: 'D' }
];
export const INCIDENTTYPE = 'Incident Type';
export const INCIDENTUPLOAD = 'Incident Data Upload';
export const INCIDENTDATAUPLOAD = 'INCIDENT DATA UPLOAD';
export const COLUMNNAMES = ['SINo', 'FileName', 'UploadedDate', 'UploadedBy', 'IncidentType', 'Status', 'Dashboard'];
export const FILE_VALIDATION_MESSAGE = 'Please upload files with .xls or .xlsx format only.';
export const SELECT_INCIDENT_VALIDATION_MESSAGE = 'Please select Incident Type before uploading.';
export const API_ERROR_MESSAGE = 'Error in proccessing data. Please try again.';
export const FILE_UPLOAD_SUCCESS_MESSAGE = 'File is uploaded successfully.';
export const NO_RECORDS = 'No records found.';

// Configurator page
export const LOSSTREE_HEADER = 'LOSS TREE CONFIGURATOR';
export const DEFAULT_SEVERITY = 'select';
export const GRADES = [
  { text: 'AA' },
  { text: 'A' },
  { text: 'B' },
  { text: 'C' }
];
export const COLUMNS = [
  { id: 1, headerText: 'Level 0', contentAlignment: 'center', headerAlignment: 'center', width: 165, fixed: true },
  { id: 2, headerText: 'Level 1', headerAlignment: 'center', width: 80 },
  { id: 3, headerText: 'Level 2', headerAlignment: 'center', width: 80 },
  { id: 4, headerText: 'Level 3', headerAlignment: 'center', width: 80 },
  { id: 5, headerText: 'Level 4', headerAlignment: 'center', width: 80 },
  { id: 6, headerText: 'Level 5', headerAlignment: 'center', contentAlignment: 'center', width: 80 },
  { id: 7, headerText: 'Aggregation', headerAlignment: 'center', width: 180 },
  { id: 8, headerText: 'Severity Grade', headerAlignment: 'center', contentAlignment: 'center', width: 85 },
  { id: 9, headerText: 'Explanation/Examples', headerAlignment: 'center', contentAlignment: 'center', width: 150 },
  { id: 10, headerText: 'Actions', headerAlignment: 'center', contentAlignment: 'center', width: 80 }
];
export const SUCCESSMSG = ' Your changes have been saved successfully.';
export const ERRORMSG = 'Error in processing data. Reason: "';

// QA-matrix page (5 tabs)
export const QAHEADER = 'QA MATRIX CREATION';
export const FIRST_TABTITLE = 'Defect Mode / Severity';
export const SECOND_TABTITLE = 'Frequency';
export const THIRD_TABTITLE = 'Cost';
export const FOURTH_TABTITLE = 'Detectability';
export const FIFTH_TABTITLE_DPI = 'DPI Calculations &';
export const FIFTH_TABTITLE_PDCA = 'PDCA Status';
export const DEFECT_TYPE = 'DEFECT TYPE';

// defect-mode tab (datatable)
export const DEFECTMODECOLUMNSNAME = ['productionDate',
'productCode', 'formatAggregation', 'format', 'airswebId', 'description', 'defectAggregation', 'severityGrade'];
export const PRODUCTION = 'Production';
export const DATE = 'Date';
export const PRODUCT = 'Product';
export const CODE = 'Code';
export const FORMAT_AGGREGATION = 'Sub-Category + Product';
export const AIRSWEB_ID = 'Airsweb ID';
export const DESCRIPTION = 'Name';
export const DEFECT_AGGREGATION = 'Defect Mode Aggregation(Level 1+2+3+4)';
export const DEFECT_SEVERITY = 'Defect Severity';
export const GRADE = 'Grade';
export const FORMAT = 'Pack Size';

// Frequency tab (datatable)
export const FREQUENCYCOLUMNSNAME = ['productionDate', 'formatAggregation', 'blockedproduct',
'ProductionAmount', 'UoM', 'affectedProduct', 'frequencyScore'];
export const BLOCKEDPRODUCT_NO = '# of Blocked Product';
export const TOTAL_PRODUCTION_AMOUNT = 'Total Production Amount';
export const AVERAGE_BATCH = '(Average Batch)';
export const UOM = 'UoM (Pcs-Kgs-Pallets-Cases-Cus)';
export const PERCENTAGE_AFFECTEDPRODUCT = '% of Affected Product';
export const FREQUENCY_SCORE = 'Frequency Score';

// Cost tab
export const COSTCOLUMNSNAME = ['productionDate', 'formatAggregation',
  'cost1piece', 'totaldefectcost', '%fromtotalcost', 'costscore'];
export const COSTSCORE = 'Cost Score';
export const FROMTOTALCOST = '% from Total Cost';
export const COST1PIECE = 'Cost of 1 Piece';
export const TOTALDEFECTCOST = 'Total Defect Cost';

// detection point tab
export const DETECTIONPOINTCOLUMNSNAME = ['productionDate', 'formatAggregation',
  'occurance', 'ms', 'criticalComponent', 'detectionType', 'detectionpoint', 'detectability'];
export const DETECTABILITY = 'Detectability';
export const MS = '4Ms';
export const DETECTIONPOINT = 'Detection Point';
export const OCCURANCE = 'Occurance';
// export const VALUES = ['RO Capper', 'Labeler', 'Palletisator', 'Valve Sorter']; to be removed once getting Api
export const VALUES = ['Freezer 90-1', 'Freezer 90-2', 'Freezer 91-1', 'Freezer-91-2', 'Kuka Robot L22', 'Tunnel',
  'X-Ray', 'Pallettizzatore', 'Checkweigher', 'HANDLING FANTINI', 'BigDrum', 'Formatrice Nuova', 'Formatrice Vecchia',
  'Vortex', 'Chiuditrice Lato 23', 'Chiuditrice LMuro', 'Mannara Lato 24', 'Mannara Lato Muro'];
export const DETECTIONPOINTVALUES = ['E incidents (Supplier)', 'Rework, Amber CRQS, waste', 'D-incidents', 'Customer Claims', 'On-shelf CRQS,  point of sale', 'Market Place Incident (A, B, C)', 'Consumer Complaint'];
export const FOURMS = ['Man', 'Machine', 'Method', 'Material'];
export const PDCASTATUSVALUES = ['Plan', 'Do', 'Check', 'Act'];
export const DETECTIONTYPEVALUES = ['Poka YokeError Proofing', '100% Inspection by measuring', 'Random inspection by measuring Random visual/sensory', 'check100% visual/sensory check'];

// PDCA and DPI calc tab
export const DPIANDPDCACOLUMNSNAME = ['productionDate', 'formatAggregation', 'defectPriorityIndex', 'vstotalDPI',
  'cumulativeDPI', 'dpiDefectClass', 'pdca'];
export const VSTOTALDPI = '% DPI vs Total DPI';
export const CUMMULATIVEDPI = 'Cumulative DPI';
export const DPIDEFECTCLASS = 'DPI Defect Class';
export const PDCASTATUS = 'PDCA Status';

// Standard Instruction page
export const STDPRIORITIZATION = 'STANDARD OF PRIORITIZATION';
export const PRIORITYINDEX = 'Priority Index';
export const COST = 'Cost';
export const FREQUENCY = 'Frequency';
export const DETECTABILITYINDEX = 'Detectability Index';
export const SEVERITY = 'Severity';
export const EQUALTO = '=';
export const MULITPLY = 'X';
export const STDINSTRUCTION = 'STANDARD INSTRUCTION';
export const INTERNALDETPOINTS = 'Internal Detection Points';
export const EXTERNALDETPOINTS = 'External Detection Points';
export const CLASSIFICATIONDPI = 'Classification of Defects based on DPI';

// Dashboard
export const DASHBOARD = 'DASHBOARD';
export const MDISTRIBUTION = '4M Distribution';
export const PDCA = 'PDCA';
export const DPI = 'Defect Priority Index';
export const FORMULAE = '= S X F X C X ';
export const DASHBOARDCOLUMNNAMES = ['Severity Grade', 'Short Description', 'Criteria', 'Index'];
export const COSTCOLUMNNAMES = ['Criteria', 'From (%)', 'To (%)', 'Index'];
export const DETECTABILITYCOLUMNNAMES = ['Criteria', 'Index'];
export interface SeverityGrade {
  SGrade: string;
  Desc: string;
  Criteria: string;
  Index: number;
}
export const DASHBOARDVALUE = '--Select--';
export const DASHBOARDINFO: any[] = [
  { name: 'lossTree', value: 'LossTree v/s DPI' },
  { name: 'Process', value: 'LossTree v/s Machines' }
];
export const SGTABLEDATA: SeverityGrade[] = [
  { SGrade: 'AA', Desc: 'Critical Issues', Criteria: 'Customer Safety T5Z', Index: 500 },
  { SGrade: 'A', Desc: 'Major Issues', Criteria: 'Legal and Regulatory (No fatal issues)', Index: 100 },
  { SGrade: 'B', Desc: 'Serious Issues', Criteria: 'Likely to be noticed', Index: 50 },
  { SGrade: 'C', Desc: 'Minor Issues', Criteria: 'Likely not be noticed', Index: 10 }
];
export const FREQTABLEDATA = [
  { Criteria: 'Very High', From: '>6.68', To: '<100', Index: 500 },
  { Criteria: 'High', From: '>0.621', To: '<50', Index: 200 },
  { Criteria: 'Moderate', From: '>0.0233', To: '<25', Index: 100 },
  { Criteria: 'Low', From: '>=0', To: '<1', Index: 50 }
];
export const COSTTABLEDATA = [
  { Criteria: 'Loss Cost', From: '500', To: '<100', Index: 500 },
  { Criteria: 'Loss Cost', From: '200', To: '<50', Index: 200 },
  { Criteria: 'Loss Cost', From: '100', To: '<25', Index: 100 },
  { Criteria: 'Loss Cost', From: '75', To: '<1', Index: 50 }
];
export const CLASSIFICATIONTABLEDATA = [
  { value: 'AA=<50%' }, { value: 'A>50%<=70%' }, { value: 'B>70%<=90%' }, { value: 'C>90%' }
];
export const INTERNALDPTABLEDATA = [
  { value: 'E Incidents (Supplier)' }, { value: 'Rework' }, { value: 'Amber CRQS' }, { value: 'Waste' }, { value: 'D-incidents' }
];
export const EXTERNALDPTABLEDATA = [
  { desc: 'Distribution', value: 'Pallet Q non-conformance' },
  { desc: 'Customer', value: 'Customer Q non-conformance' },
  { desc: 'Consumer', value: 'Consumer Complaints' },
  { desc: 'Market Place Incident', value: 'Market Place incidents(A,B,C Incidents)' },
  { desc: 'Storage on the shelf', value: 'On-shelf CRQS, point of sale' }
];

// API Related
// export const SUBSCRIPTIONKEY = '1353f865d012425880e82426fd0e0ba5';
export const APPLICATIONCONTENT = 'application/json; charset=utf-8';
export const ACCESSCONTROL = '*';
export const SETTINGS = 'settings';
export const LOSSTREE = 'settings/factories/';
export const FILEUPLOAD = 'incidents/files/';
export const LOSSTREEEDIT_GETFILES = 'incidents/files';
export const QAMATRIXDATA = 'qaMetrices/';
export const SAVEQAMATRIXDATA = 'qaMetrices/';
export const MACHINELIST = 'machines';
export const DFOSMENUITEMS = 'menuitems';
export const DFOSCATEGORYMENUITEMS = 'linedetails/regions/';
export const DFOSFACTORIESURL = '/factories/';
export const DFOSLINEIDURL = '/lines/';
export const DFOSSAVEFACTORYCONFIGURATION = 'settings/factoryConfig';

export const DEFECTCLASSVALUES: any[] = [
  { name: 'AA', value: 'AA' },
  { name: 'A', value: 'A' },
  { name: 'B', value: 'B' },
  { name: 'C', value: 'C' }
];
export const LOSSTREECOLUMNNAMES = ['Level0', 'Level1', 'Level2', 'Level3', 'Level4', 'Level5', 'Aggregation', 'Severity Grade',
'Explanation/Examples'];
