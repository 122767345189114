<span class="loading" *ngIf="loading">
    <em class="fa fa-spinner fa-spin" style="font-size:40px;color:white"></em>
</span>
<app-header></app-header>
<app-side-menu-barqa></app-side-menu-barqa>
<div class="main-content">
    <div class="heading">
        <label class="heading-text">{{QAheader}}</label>
    </div>
    <div class="tabHeight" id="qaForm" [formGroup]="qamatrixForm" *ngIf="!loading">
        <mat-horizontal-stepper linear #stepper [selectedIndex]="0" >
            <!-- mat step for defect mode tab -->
            <mat-step>
                <ng-template matStepLabel>
                    <div>
                        <div style="text-align:center">
                            <img  [ngClass]="activeFreq ? 'fade':'nofade'" src="../../assets/images/qaMatrix/Defect-Mode_Blue.svg" alt="defectModeimg" />
                        </div>
                        <div style="text-align: center">
                            {{firsttabTitle}}
                        </div>
                    </div>
                </ng-template>
                <div style="padding:10px;">
                    <app-defect-mode-tab [dataSourceInput]= "matrixDataTab" [qaMatrixData]="matrixData" (pushSaveData)="callParentSave($event)" (changeImageStatus)="changeTabImage($event)"></app-defect-mode-tab>
                </div>
            </mat-step>
            <!-- mat step for frequency tab -->
            <mat-step formGroupName="frequencyDetails" [stepControl]="qamatrixForm.get('frequencyDetails').status">
                <ng-template matStepLabel >
                    <div  (click)="changeImageFreq()">
                        <div style="text-align:center">
                            <img *ngIf="!activeFreq" src="../../assets/images/qaMatrix/Frequency_Gray.svg" alt="frequencyimg" />
                            <img *ngIf="activeFreq" [ngClass]="activeCost ? 'fade':'nofade'" src="../../assets/images/qaMatrix/Frequency_Blue.svg" alt="frequencyimg" />
                        </div>
                        <div style="text-align: center">
                                {{secondtabTitle}}
                        </div>
                    </div>
                </ng-template>
                <div style="padding:10px;">
                   <app-frequency-tab *ngIf="activeFreq" [allTabData]="allTabData" [qaMatrixStatusValue]="qaMatrixStatusValue" [qaForm]="qamatrixForm" [dataSourceInput]= "matrixDataTab" [qaMatrixData]="matrixData" (pushSaveData)="callParentSave($event)" (changeImageStatus)="changeTabImage($event)"
                   (recalculateFreqTab)=recalculateFreqTab($event)></app-frequency-tab>
                </div>
            </mat-step>
            <!-- mat step for cost tab -->
            <mat-step formGroupName="costDetails" [stepControl]="qamatrixForm.get('costDetails').status">
                <ng-template matStepLabel>
                    <div (click)="changeImageCost()">
                        <div style="text-align:center" >
                            <img *ngIf="!activeCost" src="../../assets/images/qaMatrix/Cost_Gray.svg" alt="costimg" />
                            <img *ngIf="activeCost" [ngClass]="activeDetection ? 'fade':'nofade'" src="../../assets/images/qaMatrix/Cost_Blue.svg" alt="costimg" />
                        </div>
                        <div style="text-align: center">
                                {{thirdtabTitle}}
                        </div>
                    </div>
                </ng-template>
                <div style="padding:10px;">
                    <app-cost-tab *ngIf="activeCost" [allTabData]="allTabData" [qaMatrixStatusValue]="qaMatrixStatusValue" [qaForm]="qamatrixForm" [dataSourceInput]= "matrixDataTab" [qaMatrixData]="matrixData" (pushSaveData)="callParentSave($event)" (changeImageStatus)="changeTabImage($event)"
                    (recalculateCostTab)="recalculateCostTab($event)"></app-cost-tab>
                </div>
            </mat-step>
            <!-- mat step for detection point tab -->
            <mat-step>
                <ng-template matStepLabel>
                    <div (click)="changeImageDetection()">
                        <div style="text-align:center" >
                            <img *ngIf="!activeDetection"src="../../assets/images/qaMatrix/Detection-Point_Gray.svg" alt="detectionimg" />
                            <img *ngIf="activeDetection" [ngClass]="activeDpi ? 'fade':'nofade'" src="../../assets/images/qaMatrix/Detection-Point_Blue.svg" alt="costimg" />
                        </div>
                        <div style="text-align: center">
                                {{fourthtabTitle}}
                        </div>
                    </div>
                </ng-template>
                <div style="padding:10px;">
                    <app-detection-point-tab *ngIf="activeDetection" [allTabData]="allTabData" [qaMatrixStatusValue]="qaMatrixStatusValue" [qaForm]="qamatrixForm" [dataSourceInput]= "matrixDataTab" [qaMatrixData]="matrixData" (pushSaveData)="callParentSave($event)" [machineDetails]="machineDetails" (changeImageStatus)="changeTabImage($event)"
                    [costTabStatus]="costTabStatus" [freqTabStatus]="freqTabStatus"></app-detection-point-tab>
                </div>
            </mat-step>
            <!-- mat step for dpi calcualtion tab -->
            <mat-step>
                <ng-template matStepLabel>
                    <div  (click)="changeImageDpi()">
                        <div style="text-align:center" >
                            <img *ngIf="!activeDpi" src="../../assets/images/qaMatrix/DPI_PDCA_Gray.svg" alt="pdcaimg" />
                            <img *ngIf="activeDpi" src="../../assets/images/qaMatrix/DPI_PDCA_Blue.svg" alt="costimg" />
                        </div>
                        <div style="text-align: center">
                                {{fifthtabTitledpi}} {{fifthtabTitlepdca}}
                        </div>
                    </div>
                </ng-template>
                <div style="padding:10px;">
                   <app-dpi-cal-pdca *ngIf="activeDpi" [allTabData]="allTabData" [costTabStatus]="costTabStatus" [freqTabStatus]="freqTabStatus" [dataSourceInput]= "matrixDataTab" [allTabData]="allTabData" (pushAllTabData)="callParentApiSave($event)" (changeImageStatus)="changeTabImage($event)"></app-dpi-cal-pdca>
                </div>
            </mat-step>

        </mat-horizontal-stepper>
    </div>
</div>