<app-header></app-header>
<app-side-menu-bar></app-side-menu-bar>
<div class="main-content">
    <div class="heading">
        <label class="heading-text">LOSS TREE CONFIGURATOR</label>
        <span class="addBtn">
        <button class="btn btn-primary enable save-btn"  *ngIf="enableSaveBtn">Save</button>
        <button class="btn btn-primary disable save-btn"  *ngIf="!enableSaveBtn">Save</button>

        <button class="btn btn-primary" [ngClass]="clickedLevel1 ? 'enable': 'disable'">Add New</button>
     </span>
    </div> 
    <div class="tabHeight">
    </div>
</div>