import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DEFAULT_SEVERITY, GRADES } from 'src/app/common/constants/labels';
import { Role } from 'src/app/common/models/role';
import { LossTreeService } from '../loss-tree.service';
@Component({
  selector: 'app-has-relative-table',
  templateUrl: './has-relative-table.component.html',
  styleUrls: ['./has-relative-table.component.scss']
})
export class HasRelativeTableComponent implements OnInit {
  @Input() relatives;
  @Output() btnEnable: EventEmitter<any> = new EventEmitter();
  @Output() emitLossTreeData: EventEmitter<any> = new EventEmitter();
  showSeverity = false;
  defaultSeverity;
  severity = GRADES;
  rowID;
  level5Clicked = false;
  expClicked = false;
  sGradeClicked = false;
  displayedColumns: string[] = [
    'lossTreeID', 'level1', 'level2', 'level3', 'level4', 'level5', 'severityGrade', 'explanationExamples'];
  role;
  apiData;
  rowCount = [];
  constructor(private losstreeService: LossTreeService) { }

  ngOnInit() {
    this.losstreeService.getLossTreeData().subscribe((data) => {
      this.apiData = data;
    });
  }
  // method to display changed value of dropdown
  focusOutSeverity() {
    this.showSeverity = false;
  }
  // method to toggle angle arrow icon(up/down)
  severityDefaultValue(val) {
    this.rowID = val;
    this.showSeverity = !this.showSeverity;
  }
   /* istanbul ignore next */
  selectedSeverity(value, index, element) {
    if (this.rowCount.length === 0) {
      this.rowCount.push({
        lossTreeID: element.lossTreeID,
        value: value.text
      });
    } else {
      const indexCount = this.rowCount.findIndex((e) => e.lossTreeID === element.lossTreeID);

      if (indexCount === -1) {
        this.rowCount.push({
          lossTreeID: element.lossTreeID,
          value: value.text
        });
      } else {
        this.rowCount[indexCount].value = value.text;
      }
    }
    this.rowID = index;
    this.showSeverity = false;
    this.apiData.forEach(element1 => {
      element1.lossTreeParent.forEach(data => {
        if (data.lossTreeID === element.lossTreeID) {
          this.defaultSeverity = data.severityGrade;
        }
      });
    });
    if (this.defaultSeverity !== value.text) {
      this.sGradeClicked = true;
      this.btnEnable.emit(true);
      element.severityGrade = value.text;
      this.emitLossTreeData.emit(element);
    } else {
      element.severityGrade = value.text;
      this.emitLossTreeData.emit(element);
      let c1 = 0;

      for (let k = 0; k < this.rowCount.length; k++) {
        for (let i = 0; i < this.apiData.length; i++) {
          for (let j = 0; j < this.apiData[i].lossTreeParent.length; j++) {
            if ((this.rowCount[k].value === this.apiData[i].lossTreeParent[j].severityGrade) && (this.rowCount[k].lossTreeID === this.apiData[i].lossTreeParent[j].lossTreeID)) {
              this.sGradeClicked = false;
              c1++;
            }
          }
        }
      }
      if (c1 === this.rowCount.length) {
        this.sGradeClicked = false;
      } else {
        this.sGradeClicked = true;
      }
      if (!this.expClicked && !this.level5Clicked && !this.sGradeClicked) {
        this.btnEnable.emit(false);
      }
    }
  }
  get isAuthorized() {
    this.role = localStorage.getItem('role');
    if (this.role === Role.qualityManager || this.role === Role.readOnlyUser) {
      return false;
    } else {
      return true;
    }
  }
  emitLevel5data() {
    this.btnEnable.emit(true);
  }
  emitData(element, value) {
    if (element.level5 !== value.target.value) {
      this.level5Clicked = true;
      element.level5 = value.target.value;
      this.emitLossTreeData.emit(element);
    } else {
      if (!this.expClicked && !this.level5Clicked && !this.sGradeClicked) {
        this.btnEnable.emit(false);
      }
    }
  }
  emitDataExp(element, value) {
    if (element.explanationExamples !== value.target.value) {
      this.expClicked = true;
      element.explanationExamples = value.target.value;
      this.emitLossTreeData.emit(element);
    } else {
      if (!this.expClicked && !this.level5Clicked && !this.sGradeClicked) {
        this.btnEnable.emit(false);
      }
    }
  }
}
