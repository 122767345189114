import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { APPLICATIONCONTENT, ACCESSCONTROL } from '../../constants/labels';
import { environment } from '../../../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};
@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(private http: HttpClient) {
  }

  public post<T>(path: string, body: any | null): Observable<T> {
    return this.http.post<T>(path, body, httpOptions);
  }
  public get<T>(path: string): Observable<T> {
    return this.http.get<T>(path, httpOptions);
  }
  public delete<T>(path): Observable<T> {
    return this.http.delete<T>(path);
  }

  public put<T>(path: string, body: any | null): Observable<T> {
    return this.http.put<T>(path, body, httpOptions);
  }
  public inputXML(file: File, incidentType: any, path: string): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();
    formData.append('files', file);
    formData.append('userId', localStorage.getItem('UserName'));
    return this.http.post(path, formData, { reportProgress: true, observe: 'events' });
  }
}
