import { Component, OnInit } from '@angular/core';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent implements OnInit {

  constructor(private adalSvc: MsAdalAngular6Service) { }

  ngOnInit() {
  }
  // it will redirect to login page
  logout() {
    this.adalSvc.logout();
    localStorage.clear();
  }
}
