import { Component, OnInit, Inject, Input } from '@angular/core';
import { Role } from '../common/models/role';
import { LOSSTREE_HEADER, LOSSTREECOLUMNNAMES, DEFAULT_SEVERITY, GRADES } from '../common/constants/labels';
import { Router, ActivatedRoute } from '@angular/router';
import { StandarInstructionService } from '../standard-instruction/standar-instruction.service';
import { MatTableDataSource, MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { expandableTableRowAnimation } from './expandable-table-row-animation';
import { LossTreeService } from './loss-tree.service';
import { DialogSuccessComponent } from '../factory-information/factory-information.component';

@Component({
  selector: 'app-loss-tree',
  templateUrl: './loss-tree.component.html',
  styleUrls: ['./loss-tree.component.scss'],
  animations: [expandableTableRowAnimation]
})
export class LossTreeComponent implements OnInit {
  role;
  LossTreeHeader = LOSSTREE_HEADER;
  dataSource;
  editTimeout: any = null;
  isEditActive = false;
  originalText = '';
  users: any = [];
  clickedLevel1 = false;
  enableSaveBtn = false;
  displayedColumns: string[] = [
    'expandIcon',
    'Level 1',
    'Level 2',
    'Level 3',
    'Level 4',
    'Level 5',
    'Severity Grade',
    'Explanation/Example'
  ];
  selectedLevel0;
  saveDataObject = [];
  validateSG;
  loading = true;
  constructor(private service: StandarInstructionService, public dialog: MatDialog, private losstreeservice: LossTreeService, private router: Router) { }
  /* istanbul ignore next */
  ngOnInit() {
    this.losstreeservice
      .getLossTreeData()
      .subscribe((data) => {
        this.loading = false;
        this.users = data;
      }, (error) => {
        this.loading = false;
      });
  }
  get isAuthorized() {
    this.role = localStorage.getItem('role');
    if (this.role === Role.qualityManager || this.role === Role.readOnlyUser) {
      return false;
    } else {
      return true;
    }
  }
  addRow(data) {
    this.selectedLevel0 = data.level0;
    if (!this.enableSaveBtn) {
      this.clickedLevel1 = true;
    }
  }
  /* istanbul ignore next */
  addChild() {
    this.openDialogPopUp();
    this.clickedLevel1 = false;
  }
  /* istanbul ignore next */
  openDialogPopUp(): void {
    const dialogRef = this.dialog.open(AddLossTreeDialogPopUpComponent, {
      data: { level0: this.selectedLevel0 }
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }
  enableSaveBtnFn(value) {
    this.enableSaveBtn = value;
    this.clickedLevel1 = false;
  }
  getSaveData(element) {
    this.saveDataObject.push(element);
  }
  // end of add loss tree--------------------------
  // method for bulk update
  /* istanbul ignore next */
  saveLossTree(id) {
    this.enableSaveBtn = false;
    /* istanbul ignore next */
    this.losstreeservice.editLossTreeData(this.saveDataObject)
      .subscribe((response) => {
        this.enableSaveBtn = false;
        this.openDialogSuccess();
      },
        (error) => {
          this.validateSG = error.error.message;
          if (error.error.message === 'Severity Grade cannot be updated more than thrice a year!') {
            this.openDialogError(id);
          }
        });
    this.enableSaveBtn = false;
    this.saveDataObject = [];
  }
  /* istanbul ignore next */
  openDialogError(id): void {
    const dialogRef = this.dialog.open(DialogOverviewExampleDialogComponent, {
      data: { name: this.validateSG }
    });
    if (this.validateSG === 'Loss Tree Already Exists!') {
      dialogRef.afterClosed().subscribe(result => {
        this.clickedLevel1 = false;
        this.enableSaveBtn = false;
      });
    } else {
      dialogRef.afterClosed().subscribe(result => {
        this.losstreeservice.reloadLossTree();
      });
    }
  }
  /* istanbul ignore next */
  openDialogSuccess() {
    this.dialog.open(DialogSuccessComponent, {
      data: { name: ' Loss Tree configuration details are saved successfully.' }
    });
    this.losstreeservice.reloadLossTree();
  }
}
export interface DialogData {
  level0: any;
  validateSG: any;
}
@Component({
  selector: 'app-losstree-popup-dialog',
  templateUrl: 'addlosstree-dialog.html',
  styleUrls: ['./addlosstree-dialog.scss'],
})

// Component loads the confirmation popup
export class AddLossTreeDialogPopUpComponent {
  showSeverity = false;
  rowID;
  defaultSeverity = DEFAULT_SEVERITY;
  mandatoryFieldsHasValue = false;
  level1; level2; level3; level4; level5; example;
  newLevel1 = ''; newLevel2 = ''; newLevel3 = ''; newLevel4 = ''; newLevel5 = ''; newExample = '';
  severity = GRADES;
  validateSG;
  constructor(
    public dialogRef: MatDialogRef<AddLossTreeDialogPopUpComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, private losstreeservice: LossTreeService,
    private dialog: MatDialog, private router: Router) { }
  onNoClick(): void {
    this.dialogRef.close();
    this.newLevel1 = ''; this.newLevel2 = ''; this.newLevel3 = ''; this.newLevel4 = ''; this.defaultSeverity = DEFAULT_SEVERITY;
  }
  focusOutSeverityWhileAdd() {
    this.showSeverity = false;
  }
  severityDefaultValueWhileAdd() {
    this.showSeverity = !this.showSeverity;
  }
  selectedSeverityWhileAdd(value, index) {
    this.rowID = index;
    this.showSeverity = false;
    this.defaultSeverity = value.text;
    /* istanbul ignore next */
    if (this.newLevel1 !== '' && this.newLevel2 !== '' && this.newLevel3 !== '' && this.newLevel4 !== '' && this.defaultSeverity !== DEFAULT_SEVERITY) {
      this.mandatoryFieldsHasValue = true;
    } else {
      this.mandatoryFieldsHasValue = false;
    }

  }
  /* istanbul ignore next */
  level1hasValue(val) {
    this.newLevel1 = val;
    if (this.newLevel1 !== '' && this.newLevel2 !== '' && this.newLevel3 !== '' && this.newLevel4 !== '' && this.defaultSeverity !== DEFAULT_SEVERITY) {
      this.mandatoryFieldsHasValue = true;
    } else {
      this.mandatoryFieldsHasValue = false;
    }

  }
  /* istanbul ignore next */
  level2hasValue(val) {
    this.newLevel2 = val;
    if (this.newLevel1 !== '' && this.newLevel2 !== '' && this.newLevel3 !== '' && this.newLevel4 !== '' && this.defaultSeverity !== DEFAULT_SEVERITY) {
      this.mandatoryFieldsHasValue = true;
    } else {
      this.mandatoryFieldsHasValue = false;
    }
  }
  level3hasValue(e) {
    this.newLevel3 = e;
    if (this.newLevel1 !== '' && this.newLevel2 !== '' && this.newLevel3 !== '' && this.newLevel4 !== '' && this.defaultSeverity !== DEFAULT_SEVERITY) {
      this.mandatoryFieldsHasValue = true;
    } else {
      this.mandatoryFieldsHasValue = false;
    }
  }
  level4hasValue(val) {
    this.newLevel4 = val;
    if (this.newLevel1 !== '' && this.newLevel2 !== '' && this.newLevel3 !== '' && this.newLevel4 !== '' && this.defaultSeverity !== DEFAULT_SEVERITY) {
      this.mandatoryFieldsHasValue = true;
    } else {
      this.mandatoryFieldsHasValue = false;
    }

  }
  level5hasValue(val) {
    this.newLevel5 = val;
  }
  typedExample(val) {
    this.newExample = val;
  }
  /* istanbul ignore next */
  saveAddedLossTree() {
    const addLosstreeObject = [];
    addLosstreeObject.push({
      lossTreeID: null,
      level0: this.data.level0,
      level1: this.newLevel1,
      level2: this.newLevel2,
      level3: this.newLevel3,
      level4: this.newLevel4,
      level5: this.newLevel5,
      aggregation: this.newLevel1 + '>' + this.newLevel2 + '>' + this.newLevel3 + '>' + this.newLevel4,
      severityGrade: this.defaultSeverity,
      linkwithT5Z: 'No',
      explanationExamples: this.newExample
    });

    this.losstreeservice.editLossTreeData(addLosstreeObject).subscribe((response) => {
      this.validateSG = response.message;
      this.losstreeservice.reloadLossTree();
      this.openDialogSuccess();
    },
      (error) => {
        this.validateSG = error.error;
        this.openDialogError();
        this.losstreeservice.reloadLossTree();
      });
  }
  /* istanbul ignore next */
  openDialogSuccess() {
    this.dialog.open(DialogSuccessComponent, {
      data: { name: ' Loss Tree configuration details are saved successfully.' }
    });
  }
  /* istanbul ignore next */
  openDialogError(): void {
    this.dialog.open(DialogOverviewExampleDialogComponent, {
      data: { name: this.validateSG }
    });
  }
}
/* istanbul ignore next */
export interface DialogData {
  name: string;
}
@Component({
  selector: 'app-dialog-overview-example-dialog',
  templateUrl: 'dialog-overview-example-dialog.html',
})

// Component loads the loss tree validation popup
/* istanbul ignore next */
export class DialogOverviewExampleDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
