import { Component, OnInit } from '@angular/core';
import { STDINSTRUCTION, STDPRIORITIZATION, PRIORITYINDEX, COST, FREQUENCY,
  DETECTABILITYINDEX, SEVERITY, EQUALTO, MULITPLY, DASHBOARDCOLUMNNAMES, SGTABLEDATA,
  COSTCOLUMNNAMES, DETECTABILITYCOLUMNNAMES, EXTERNALDETPOINTS, INTERNALDETPOINTS, CLASSIFICATIONDPI,
  FREQTABLEDATA, COSTTABLEDATA, CLASSIFICATIONTABLEDATA, DEFECT_TYPE,
  EXTERNALDPTABLEDATA, INTERNALDPTABLEDATA } from '../common/constants/labels';
import { StandarInstructionService } from './standar-instruction.service';
import { StandardInstruction, CostIndices, FrequencyIndices, DefectClassifications,
        SeverityIndices, ExternalDetectionPoints, InternalDetectionPoints,
        DetectabilityIndices } from '../common/models/standard-instruction';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-standard-instruction',
  templateUrl: './standard-instruction.component.html',
  styleUrls: ['./standard-instruction.component.scss']
})
// Component loads the standard Instruction page of the application
export class StandardInstructionComponent implements OnInit {
  standardinstruction = STDINSTRUCTION;
  stdPrioritization = STDPRIORITIZATION;
  priorityIndex = PRIORITYINDEX;
  cost = COST;
  freq = FREQUENCY;
  dIndex = DETECTABILITYINDEX;
  frequency = FREQUENCY;
  severity = SEVERITY;
  equalto = EQUALTO;
  multiply = MULITPLY;
  displayedColumns: any;
  costDisplayedColumns: any;
  detectabilityDisplayedColumns: any;
  dataSourceDetIndex: Array<DetectabilityIndices> = [];
  internalDetPoints = INTERNALDETPOINTS;
  externalDetPoints = EXTERNALDETPOINTS;
  classificationDPI = CLASSIFICATIONDPI;
  dataSourceSeverity: Array<SeverityIndices> = [];
  dataSourceFreq: Array<FrequencyIndices> = [];
  dataSourceCost: Array<CostIndices> = [];
  dataSourceClassification: Array<DefectClassifications> = [];
  defectType = DEFECT_TYPE;
  dataSourceExt: Array<ExternalDetectionPoints> = [];
  dataSourceInt: Array<InternalDetectionPoints> = [];
  loading = true;
  constructor(private stdInstructionService: StandarInstructionService) { }

  ngOnInit() {
    this.displayedColumns = DASHBOARDCOLUMNNAMES;
    this.costDisplayedColumns = COSTCOLUMNNAMES;
    this.detectabilityDisplayedColumns = DETECTABILITYCOLUMNNAMES;
    this.getStandardInstructionData();
  }

  // method to get data from api
  getStandardInstructionData() {
    this.stdInstructionService.getStandardInstructionData()
    .subscribe((response) => {
      this.dataSourceCost = response.costIndices;
      this.dataSourceFreq = response.frequencyIndices;
      this.dataSourceDetIndex = response.detectabilityIndices;
      this.dataSourceInt = response.internalDetectionPoints;
      this.dataSourceExt = response.externalDetectionPoints;
      this.dataSourceClassification = response.defectClassifications;
      this.dataSourceSeverity = response.severityIndices;
      this.loading = false;
    }, (error) => {
      this.loading = false;
    }
    );
  }
}
