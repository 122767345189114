import { Component, OnInit } from '@angular/core';
import { DASHBOARD, MDISTRIBUTION, PDCA, DPI, FORMULAE, DASHBOARDVALUE, DASHBOARDINFO, DEFECTCLASSVALUES } from '../common/constants/labels';
import { IncidentUploadService } from '../incident-upload/incident-upload.service';
import * as _ from 'lodash';
import { FourmModel, PDCAModel } from '../common/models/dashboard';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
/* This is a dashboard component which loads pareto and pie charts  */
export class DashboardComponent implements OnInit {
  dashboard = DASHBOARD;
  mdistribution = MDISTRIBUTION;
  pdca = PDCA;
  dpi = DPI;
  formulae = FORMULAE;
  showPareto = false;
  maxValue: any;
  fetchId;
  constructor(private uploadService: IncidentUploadService, private route: ActivatedRoute) {
  }
  tempChildCell = [];
  colorSeries: Array<any> = [];
  dataInfo: any;
  uniqueXData: any;
  showParetoAA = false;
  showParetoA = false;
  showParetoB = false;
  showParetoC = false;
  shownorecords = false;
  loading = true;
  fourmsData: FourmModel;
  showPieChart = false;
  showPieChartPdca = false;
  pdcaData: PDCAModel;
  cummulativeDPI: any;
  lossTreeAggregationFull: any;
  openDD = true;
  defaultDashBoardValue = DASHBOARDVALUE;
  dashBoardInfo = DASHBOARDINFO;
  showMachinesPareto = false;
  type = 'All';
  showdefectClass = true;
  defaultdefectClass = 'All';
  defectClassInfo = DEFECTCLASSVALUES;
  factoryName;
  incidentType;
  showmonth;
  ngOnInit() {
    this.fetchId = this.route.snapshot.paramMap.get('fileId');
    this.loading = true;
    this.loadParetoData(this.fetchId);
  }
  /* filter based on DPI class */
  /* istanbul ignore next */
  filterAA(value) {
    this.type = value;
    this.loading = true;
    this.showPareto = false;
    this.showParetoAA = false;
    this.showParetoA = false;
    this.showParetoB = false;
    this.showParetoC = false;
    this.shownorecords = false;
    this.showMachinesPareto = false;
    this.tempChildCell = [];
    this.cummulativeDPI = [];
    this.lossTreeAggregationFull = [];
    this.tempChildCell.push('DPI Score');
    this.cummulativeDPI.push('CumulativeDPI');
    if (value === 'Machines') {
      this.type = 'Machines';
      this.dataInfo.forEach((subValue, index) => {
        this.tempChildCell.push(+subValue.dpi);
        this.cummulativeDPI.push(+subValue.cumulativeDPI);
        this.lossTreeAggregationFull.push(subValue.occurance);
      });
    } else {
      this.dataInfo.forEach((subValue, index) => {
        if (subValue.defectClass === value) {
          this.tempChildCell.push(+subValue.dpi);
          this.cummulativeDPI.push(+subValue.cumulativeDPI);
          this.lossTreeAggregationFull.push(subValue.qualityIncidentType + '>' + subValue.characterizeLossLevel1 + '>' + subValue.characterizeLossLevel2
            + '>' + subValue.characterizeLossLevel3 + '>' + subValue.characterizeLossLevel4);
        }
      });
    }
    setTimeout(() => {
      if (this.tempChildCell.length > 1) {
        if (value === 'AA') {
          this.showParetoAA = true;
        } else if (value === 'A') {
          this.showParetoA = true;
        } else if (value === 'B') {
          this.showParetoB = true;
        } else if (value === 'C') {
          this.showParetoC = true;
        } else {
          this.showMachinesPareto = true;
        }
      } else {
        if (value === 'AA') {
          this.showParetoAA = true;
        } else if (value === 'A') {
          this.showParetoA = true;
        } else if (value === 'B') {
          this.showParetoB = true;
        } else if (value === 'C') {
          this.showParetoC = true;
        }
        this.shownorecords = true;
      }
      this.loading = false;
    }, 500);
  }
  /* resets the data removing filter */
  /* istanbul ignore next */
  loadAllData() {
    this.type = 'All';
    this.defaultdefectClass = this.type;
    this.showParetoAA = false;
    this.showParetoA = false;
    this.showParetoB = false;
    this.showParetoC = false;
    this.shownorecords = false;
    this.showMachinesPareto = false;
    this.loading = true;
    this.tempChildCell = [];
    this.cummulativeDPI = [];
    this.lossTreeAggregationFull = [];
    this.loadParetoData(this.fetchId);
  }

  /*load QAMAtrix data for the pareto chart */
  /* istanbul ignore next */
  loadParetoData(id) {
    this.uploadService.getQAMatrixDataFileIdDashboard(id).subscribe((response) => {
      if (response.qaMatrixIncidents !== undefined) {
        this.loading = false;
        this.factoryName = response.factory;
        this.showmonth = '';
        response.listYearMonth.forEach((subValue, index) => {
            subValue.month.forEach(element => {
              if (this.showmonth) {
                this.showmonth = this.showmonth + ', ' + element + '-' + subValue.year;
              } else {
                this.showmonth = element + '-' + subValue.year;
              }
            });
        });
        this.maxValue = Math.max.apply(Math, response.qaMatrixIncidents.map((o) => {
          return o.dpi;
        }));
        this.dataInfo = response.qaMatrixIncidents;
        this.incidentType = response.qaMatrixIncidents[0].qualityIncidentType;
        this.fourmsData = {
            Man : response.fourMsModel.man,
            Machine : response.fourMsModel.machine,
            Method : response.fourMsModel.method,
            Material : response.fourMsModel.material
        };
        this.pdcaData = {
          NotStarted : response.pdcaStatusModel.notStarted,
          Plan : response.pdcaStatusModel.plan,
          Do : response.pdcaStatusModel.do,
          Check : response.pdcaStatusModel.check,
          Act: response.pdcaStatusModel.act
        };
        this.dataInfo.sort((a, b) => {
          return (+b.dpi) - (+a.dpi);
        });
        this.showPieChartPdca = true;
        this.showPieChart = true;
        this.tempChildCell = [];
        this.cummulativeDPI = [];
        this.lossTreeAggregationFull = [];
        this.tempChildCell.push('DPI Score');
        this.cummulativeDPI.push('CumulativeDPI');
        this.dataInfo.forEach((subValue, index) => {
          this.tempChildCell.push(+subValue.dpi);
          this.cummulativeDPI.push(+subValue.cumulativeDPI);
          this.lossTreeAggregationFull.push(subValue.qualityIncidentType + ' > ' + subValue.characterizeLossLevel1 + ' > ' + subValue.characterizeLossLevel2
            + ' > ' + subValue.characterizeLossLevel3 + ' > ' + subValue.characterizeLossLevel4);
        });
        setTimeout(() => {
          this.loading = false;
          this.showPareto = true;
        }, 1000);
      }
    });
    this.loading = false;
  }
  focusOutdefectClass() {
    this.showdefectClass = true;
  }
  defectClassDefaultValue() {
    this.showdefectClass = !this.showdefectClass;
  }
  /* istanbul ignore next */
  selecteddefectClass(item) {
    this.defaultdefectClass = item.value;
    if (item.value === 'All') {
      this.loadAllData();
    } else {
      if (this.defectClassInfo.filter(item1 => item1.value === 'All').length === 0) {
        this.defectClassInfo.push({ name: 'All', value: 'All' });
      }
      this.filterAA(item.value);
    }
  }

}
