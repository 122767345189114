import { Directive, Component, OnInit, Input, Output, EventEmitter, ElementRef } from '@angular/core';
import {
  PRODUCTION, DATE, FORMAT_AGGREGATION, AIRSWEB_ID,
  COST1PIECE, TOTALDEFECTCOST, FROMTOTALCOST, COSTSCORE, COSTCOLUMNSNAME,
} from '../../common/constants/labels';
import { CostModeTab } from 'src/app/common/models/qa-matrix-tab';
import { QaMatrixDataModel } from 'src/app/common/models/qa-matrix-save';
import { ApiService } from '../../common/services/api/api.service';
import { FormGroup } from '@angular/forms';
import { Role } from 'src/app/common/models/role';

@Component({
  selector: 'app-cost-tab',
  templateUrl: './cost-tab.component.html',
  styleUrls: ['./cost-tab.component.scss']
})
// Component loads the cost page of the application
export class CostTabComponent implements OnInit {
  InputValue: any;
  date = DATE;
  production = PRODUCTION;
  formatAggregation = FORMAT_AGGREGATION;
  airswebId = AIRSWEB_ID;
  costof1piece = COST1PIECE;
  totalDefectCost = TOTALDEFECTCOST;
  totalCost = FROMTOTALCOST;
  costScore = COSTSCORE;

  displayedColumns: any;
  dataSource: any;
  @Input() dataSourceInput: any;
  @Input() qaMatrixData: any;
  @Input() qaMatrixStatusValue: any;
  @Input() allTabData: any;
  @Output() pushSaveData: EventEmitter<Array<QaMatrixDataModel>> = new EventEmitter();
  @Output() changeImageStatus: EventEmitter<any> = new EventEmitter();
  @Output() recalculateCostTab: EventEmitter<any> = new EventEmitter();
  saveQaMatrix: Array<QaMatrixDataModel> = [];
  costData: Array<CostModeTab> = [];
  @Input() qaForm: FormGroup;
  costPerPiece = '';
  rowCount = 0;
  enableToNavigate = false;
  restrictEdit = false;
  setDefaultSave = false;
  role;
  constructor() { }
  /* istanbul ignore next */
  ngOnInit() {
    this.displayedColumns = COSTCOLUMNSNAME;
    this.dataSourceInput.forEach((e) => {
      const d = new Date(e.dateOccurredOn);
      this.costData.push({
        productionDate: this.getFormattedDate(d),
        formatAggregation: e.subCategory + ' ' + e.product,
        costOf1Piece: e.costPerPc,
        totalDefectCost: e.totalDefectCost,
        percentTotalCost: e.percentFromTotalCost ? e.percentFromTotalCost + '%' : e.percentFromTotalCost,
        costScore: e.costScore,
        blockedProduct: e.numberOfBlockedProduct
      });
      /* istanbul ignore if */
      if (+e.costPerPc > 0) {
        this.rowCount++;
      }
    });
    for (let i = 0; i < this.dataSourceInput.length; i++) {
      if (this.dataSourceInput[i].costPerPc === null || +this.dataSourceInput[i].costPerPc < 1) {
        this.enableToNavigate = false;
        break;
      } else {
        this.enableToNavigate = true;
      }
    }
    this.qaFormstatus();
    this.dataSource = this.costData;
    this.saveQaMatrix = this.qaMatrixData;
  }
  // method to update cost of one piece field
  /* istanbul ignore next */
  updateCostCalculationFields(el, comment, indexVal) {
    this.costPerPiece = comment.target.value;
    /* istanbul ignore if */
    if (this.qaMatrixStatusValue !== 'Done') {                 // dashboard is not created
      if (this.rowCount !== this.dataSource.length) {
        if (!el.costOf1Piece && (+this.costPerPiece > 0)) {
          this.rowCount++;
        } else if (el.costOf1Piece === '0' && (+this.costPerPiece > 0)) {
          this.rowCount++;
        }
      }
      if (this.setDefaultSave && (!this.costPerPiece || (this.costPerPiece === '0'))) {
        if ((el.costOf1Piece > 0) && (!this.costPerPiece || (this.costPerPiece === '0'))) {
          this.rowCount--;
        }
        if (this.rowCount === 0) {
          this.rowCount = 0;
        }
      }
      /* istanbul ignore next */
      if (this.rowCount === this.dataSource.length && (!this.costPerPiece || (this.costPerPiece === '0'))) {
        this.setDefaultSave = true;
        this.rowCount--;
      }
      if (this.rowCount < this.dataSource.length) {
        this.enableToNavigate = false;
      } else {
        this.enableToNavigate = true;
      }
      /* istanbul ignore else */
    } else {                               // for dashboard is already created
      if (+this.costPerPiece > 0) {
        if (!el.costOf1Piece && (+this.costPerPiece > 0)) {
          this.rowCount++;
        } else if (el.costOf1Piece === '0' && (+this.costPerPiece > 0)) {
          this.rowCount++;
        }
      }
      /* istanbul ignore if */
      if (this.setDefaultSave && (!this.costPerPiece || (this.costPerPiece === '0'))) {
        if ((el.costOf1Piece > 0) && (!this.costPerPiece || (this.costPerPiece === '0'))) {
          this.rowCount--;
        }
        if (this.rowCount === 0) {
          this.rowCount = 0;
        }
      }
      /* istanbul ignore if */
      if (this.rowCount === this.dataSource.length && (!this.costPerPiece || (this.costPerPiece === '0'))) {
        this.setDefaultSave = true;
        this.rowCount--;
      }
      if (this.rowCount >= this.dataSource.length) {
        this.enableToNavigate = true;
        if (this.rowCount > this.dataSource.length) {
          this.rowCount = this.dataSource.length;
        }
      } else {
        this.enableToNavigate = false;
      }
    }
    /* istanbul ignore next */
    if (+this.costPerPiece > 0) {
      let totalDefectCostSum = 0;
      let elementCount = 0;
      el.costOf1Piece = comment.target.value;
      el.totalDefectCost = (el.blockedProduct * comment.target.value);
      el.percentTotalCost = (el.totalDefectCost / totalDefectCostSum) * 100;
      el.costScore = this.calCostIndex(el.percentTotalCost.toFixed(0));
      this.dataSource.forEach((e) => {
        if (e.totalDefectCost) {
          totalDefectCostSum += e.totalDefectCost;
        }
      });
      this.dataSource.forEach((e) => {
        elementCount++;
        if (e.percentTotalCost != null) {
          const calValue = (e.totalDefectCost / totalDefectCostSum) * 100;
          e.percentTotalCost = calValue.toFixed(0);
          e.costScore = this.calCostIndex(e.percentTotalCost);
        }
      });
      for (let i = 0; i < elementCount; i++) {
        this.qaMatrixData.qaMatrixIncidents[i].costPerPc = this.dataSource[i].costOf1Piece;
        this.qaMatrixData.qaMatrixIncidents[i].totalDefectCost = this.dataSource[i].totalDefectCost;
        this.qaMatrixData.qaMatrixIncidents[i].percentFromTotalCost = this.dataSource[i].percentTotalCost;
        this.qaMatrixData.qaMatrixIncidents[i].costScore = this.dataSource[i].costScore;
      }
      for (let i = 0; i < this.dataSource.length; i++) {
        /* istanbul ignore next */
        if (this.dataSource[i].percentTotalCost) {
          this.dataSource[i].percentTotalCost = this.dataSource[i].percentTotalCost + '%';
        }
      }
      this.saveQaMatrix = this.qaMatrixData;
      this.recalculateCostTab.emit('1');
    } else {
      this.setDefaultCostValue(el, comment, indexVal);
    }
    event.preventDefault();
  }
  // method for formatting date
  getFormattedDate(date) {
    if (date) {
      const year = date.getFullYear();
      let month = (1 + date.getMonth()).toString();
      month = month.length > 1 ? month : '0' + month;
      let day = date.getDate().toString();
      day = day.length > 1 ? day : '0' + day;
      return day + '-' + month + '-' + year;
    }
  }
  // method for calculating cost index
  /* istanbul ignore next */
  calCostIndex(totalDefectCostperc) {
    if (totalDefectCostperc >= 50 && totalDefectCostperc <= 100) {
      return 5;
    }
    if (totalDefectCostperc >= 25 && totalDefectCostperc < 50) {
      return 4;
    }
    if (totalDefectCostperc >= 10 && totalDefectCostperc < 25) {
      return 3;
    }
    if (totalDefectCostperc >= 1 && totalDefectCostperc < 10) {
      return 2;
    }
    if (totalDefectCostperc >= 0 && totalDefectCostperc < 1) {
      return 1;
    }
  }
  // making form valid to navigate next tab
  /* istanbul ignore next */
  qaFormstatus() {
    return this.qaForm.get('costDetails').status === 'VALID';
  }
  // method to save changes and navigate to next tab
  /* istanbul ignore next */
  saveCost() {
    this.saveQaMatrix = this.qaMatrixData;
    this.pushSaveData.emit(this.saveQaMatrix);
    /* istanbul ignore if */
    if (this.dataSourceInput[0].costScore > 0) {
      this.qaFormstatus();
    }
    this.changeImageStatus.emit('cost/1');
  }
  // method invokes when user will click prev button
  goBackToFreq() {
    this.changeImageStatus.emit('frequency/2');
  }
  // Authorization
  get isAuthorized() {
    this.role = localStorage.getItem('role');
    if (this.role === Role.qualityManager || this.role === Role.qualityEngineer || this.role === Role.globalAdmin || this.role === Role.ITSupport) {
      return true;
    } else {
      return false;
    }
  }
  /* istanbul ignore next */
  setDefaultCostValue(el, comment, indexVal) {
    el.totalDefectCost = '';
    el.percentTotalCost = '';
    el.costScore = '';
    el.costOf1Piece = comment.target.value;
    this.qaMatrixData.qaMatrixIncidents[indexVal].costPerPc = comment.target.value;
    this.qaMatrixData.qaMatrixIncidents[indexVal].totalDefectCost = el.totalDefectCost;
    this.qaMatrixData.qaMatrixIncidents[indexVal].percentFromTotalCost = el.percentTotalCost;
    this.qaMatrixData.qaMatrixIncidents[indexVal].costScore = el.costScore;
  }
}
