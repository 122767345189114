import { FactoryConfigurationModel } from './factory-configuration';
import { LossTree } from './loss-tree';

export class StandardInstruction {
    public severityIndices: Array<SeverityIndices>;
    public costIndices: Array<CostIndices>;
    public defectClassifications: Array<DefectClassifications>;
    public detectabilityIndices: Array<DetectabilityIndices>;
    public frequencyIndices: Array<FrequencyIndices>;
    public externalDetectionPoints: Array<ExternalDetectionPoints>;
    public internalDetectionPoints: Array<InternalDetectionPoints>;
    public factoryConfigDetails: Array<FactoryConfigurationModel>;
    public lossTree: Array<LossTree>;
}
export class SeverityIndices {
    public id: number;
    public severityCriteria: string;
    public severityGrade: string;
    public severityIndex: number;
    public shortDescription: string;
}
export class CostIndices {
    public id: number;
    public costCriteria: string;
    public costIndex: string;
    public from: number;
    public to: string;
}
export class DefectClassifications {
    public defectClassification: string;
    public id: number;
}
export class DetectabilityIndices {
    public detectabilityCriteria: string;
    public detectabilityIndex: number;
    public id: number;
}
export class ExternalDetectionPoints {
    public defectTypes: string;
    public description: string;
    public id: number;
    public multiplyValue: number;
}
export class FrequencyIndices {
    public id: number;
    public frequencyCriteria: string;
    public frequencyIndex: string;
    public from: number;
    public to: string;
}
export class InternalDetectionPoints {
    public defectTypes: string;
    public id: number;
    public multiplyValue: number;
}
