import { Injectable } from '@angular/core';
import { DFOSMENUITEMS, DFOSCATEGORYMENUITEMS, DFOSFACTORIESURL, DFOSLINEIDURL, DFOSSAVEFACTORYCONFIGURATION } from '../common/constants/labels';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { ApiService } from '../common/services/api/api.service';
import { FactoryConfigurationModel } from '../common/models/factory-configuration';
@Injectable({
  providedIn: 'root'
})
// service class for FActory Information page of the application
export class FactoryInformationService {
  getMenuItemsUrl = environment.APISETTINGS + DFOSMENUITEMS;
  getCategoryItemsUrl = environment.APISETTINGS + DFOSCATEGORYMENUITEMS;
  saveFactorySaveUrl = environment.APISETTINGS + DFOSSAVEFACTORYCONFIGURATION;
  constructor(private apiService: ApiService) { }
  getMenuItems(): Observable<any> {
    return this.apiService.get(this.getMenuItemsUrl);
  }
  getCategory(cluster, factory, lineId): Observable<any> {
    const url = this.getCategoryItemsUrl + cluster + DFOSFACTORIESURL + factory + DFOSLINEIDURL + lineId;
    return this.apiService.get(url);
  }
  saveFactoryDetails(object: FactoryConfigurationModel): Observable<any> {
    return this.apiService.post(this.saveFactorySaveUrl, object);
  }
}
