import { Component, OnInit } from '@angular/core';
import { MENUITEMLIST } from '../../constants/labels';

@Component({
  selector: 'app-side-menu-bar',
  templateUrl: './side-menu-bar.component.html',
  styleUrls: ['./side-menu-bar.component.scss']
})

// Component displays side navigation menu across the application
export class SideMenuBarComponent implements OnInit {
  menuItemlist = MENUITEMLIST;
  constructor() {
  }

  ngOnInit() {
  }

}
