import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DetectionTabService {
  public qaMatrixDataTab: BehaviorSubject<any> = new BehaviorSubject<any>('');
  public qaMatrixDataTab$: Observable<any> = this.qaMatrixDataTab.asObservable();
  constructor() { }
  loadData(data) {
    this.qaMatrixDataTab.next(data);
  }
}
